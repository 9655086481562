import _ from "lodash";
import { number } from "../../config";
import store from "../../store";


/**
 * Returns object for kanbanlist
 * @param {*} task
 * @returns Object
 * @author Himanshu Negi
 */
export const getKanbanKeys = (task) => {
  const state = store.getState();
  return {
    id: task?.Id,
    name: task?.Name,
    projectTaskId: task?.ProjectTaskId,
    value: task?.StageId,
    label: task?.StageName,
    taskHistoryId: task?.taskHistoryId,
    flowIcon: task?.flowIcon,
    priorityId: task?.Priority,
    commentCount: task?.commentCount,
    newCommentCount: task?.newCommentCount,
    dueDate: task?.DueDate,
    inApproval: task?.InApproval,
    isApproved: task?.IsApproved,
    approvalStatus: task?.approvalStatus,
    assignee: task?.Assignee,
    assigneeName: task?.AssigneeName,
    isLocked: task?.IsLocked,
    isFollowed: task?.isFollowed,
    hasAccess: task?.hasAccess,
    isTaskComplete: task?.IsTaskComplete,
    priority: {
      color: "",
      priority: "Low Priority",
    },
    status: task?.StageName,
  };
};

/**
 * Initialy comapers tasks of kanban list and grid list
 * @param {*} kanbanTasks
 * @param {*} projectTasks
 * @returns {Array}
 * @author Himanshu Negi
 */
export const compareInitialTasks = (kanbanTasks, projectTasks) => {
  let newKanbanTasks = _.differenceWith(projectTasks, kanbanTasks, _.isEqual);
  newKanbanTasks = newKanbanTasks?.map((task) => getKanbanKeys(task));
  return [...newKanbanTasks];
};

/**
 * Group kanban task
 * @param {*} kanbanData
 * @param {*} group
 * @returns {Array}
 * @author Himanshu Negi
 */
export const getGroupedTasks = (kanbanData, group) => {
  let groupedTask = _.groupBy(kanbanData, group?.groupBy);
  groupedTask = _.flatMap(groupedTask);

  if (Number(group?.value) === number.ONE) groupedTask = _.orderBy(groupedTask, ["priorityId"], ["desc"]);
  if (Number(group?.value) === number.TWO) {
    groupedTask = _.orderBy(
      groupedTask,
      [
        (task) => {
          const firstWord = task?.assigneeName?.split(" ")[0]; // Extract the first word
          return firstWord ? firstWord?.toLowerCase() : task?.assigneeName; // Sort based on the first word (case insensitive)
        },
      ],
      ["asc"]
    );
  }
  return groupedTask;
};