import React, { useCallback, useEffect, useRef, useState } from 'react'
import _ from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import { matchPath } from 'react-router';
import { useLocation } from 'react-router-dom';
import { Input } from '@progress/kendo-react-inputs';
import { icon, number, quote, route } from '../../config';
import { getSearchResult } from './overallsearch.service';
import OverallSearchItems from './OverallSearchItems';
import { Popup } from '@progress/kendo-react-popup';
import { useCloseOnClickOutsideLocal } from '../../utils/common';
import { checkForWhitespace, handleArrowDown, handleArrowUp } from './search.helper.js';
import { useKeyPress } from '../../helper/commonHooks';
import { Loader } from '@progress/kendo-react-indicators';
import RecentSearch from './RecentSearch';
import { errorCode, label } from '../../config/constants.js';
import { editorEntity } from '../../editor/editor.Constant.js';
import { setContextSearchText } from '../../actions/projects.js';


const OverallSearchPopup = (props) => {
  const { anchor, setShowPopup, popupClass, insertTaskChip } = props;
  const [searchText, setSearchText] = useState("");
  const [results, setResults] = useState([]);
  const [noResultFound, setNoResultFound] = useState(false);
  const [entityName, setEntityName] = useState(props.entityName ? props.entityName : null);
  const [projectId, setProjectId] = useState(null);
  const [showLoader, setShowLoader] = useState(false);
  const [contextSearch , setContextSearch] = useState(false);
  const [cursor, setCursor] = useState({ parentIdx: 0, childIdx: 0 });
  const [selected, setSelected] = useState(null);
  const downPress = useKeyPress("ArrowDown");
  const upPress = useKeyPress("ArrowUp");
  const enterPress = useKeyPress("Enter");
  const element = document.querySelector('.active-search-item');
  const excludeList = useSelector(state => state?.linkedTasks?.excludeLinkTask);
  const searchInputRef = useRef(null);
  const controllerRef = useRef(null);
  const location = useLocation();
  const pathname = location.pathname;
  const shouldShowIcon = pathname.endsWith('queue') || pathname.endsWith('project');

  useEffect(() => window.scroll(number.ZERO, number.FIVE), [results]);
  useEffect(() => { element && element.scrollIntoView({ behavior: "smooth", block: "end", inline: "nearest" }) }, [upPress, downPress]);

  useEffect(() => {
    if (results.length && downPress) {
      setCursor((prevState) => { return handleArrowDown(prevState, results) });
    }
  }, [downPress]);

  useEffect(() => {
    if (results.length && upPress) {
      setCursor((prevState) => { return handleArrowUp(prevState, results) });
    }
  }, [upPress]);

  useEffect(() => {
    if (results.length && enterPress) {
      setSelected(results[cursor.parentIdx]?.searchResult[cursor.childIdx]);
    }

  }, [cursor, enterPress]);

  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.auth);
  const searchPopupRef = React.useRef();

  useEffect(() => {
    getEntityNameAndProjectId();
  }, [pathname])

  /**
   * gets entityName and projctId
   * @param {void}
   * @returns {void}
   */
  const getEntityNameAndProjectId = () => {
    const entityRoute = [...Object.values(route.PRIVATE_ROUTE), ...Object.values(route.SUPER_ADMIN_ROUTE), ...Object.values(route.ADMIN_ROUTE)].find((d) => matchPath(pathname, { path: d.ROUTER_PATH, exact: true, strict: false }));

    entityRoute.hasOwnProperty("ENTITY_NAME") ? setEntityName(props.entityName ? props.entityName : entityRoute["ENTITY_NAME"]) : setEntityName(null);

    const { params } = matchPath(pathname, { path: entityRoute.ROUTER_PATH, exact: true, strict: false })

    params?.projectId ? setProjectId(params?.projectId) : setProjectId(null);
  }

  /**
   * debounce api call
   * @param {string} nextValue
   * @returns {void}
   */
  const debouncedSearch = useCallback(_.debounce(async (nextValue) => {
    if (nextValue.length > number.ONE) {
      setShowLoader(true);
      if (controllerRef.current) {
        controllerRef.current.abort();
      }
      controllerRef.current = new AbortController();
      const result = await dispatch(getSearchResult({ userId: user.id, companyId: user.companyId, searchText: nextValue, entityName, entityId: projectId, excludedTasks: ((props.entityName === "Linked Task" || props.entityName === editorEntity.TASK_DESC) ? excludeList : null) }, controllerRef.current?.signal));

      if (result !== errorCode.CANCEL_REQUEST) {
        setShowLoader(false);
        const resultNotFound = result?.some((val) => val.searchResult?.length > number.ZERO);
        setNoResultFound(!resultNotFound);
        setResults(result);
      }
    } else {
      setNoResultFound(false);
      setResults([]);
    }
  }, number.FIVE_HUNDRED), [entityName, projectId])

  /**
   * handles text onChange
   * @param {object} e
   * @returns {void}
   */
  const handleOnChange = (e) => {
    const nextValue = e.target.value;
    if (!checkForWhitespace(nextValue)) return;
    setSearchText(nextValue);
    if(!contextSearch){
    if (searchText.trim() !== nextValue.trim())
      debouncedSearch(nextValue.trim());
  }
  else{
    dispatch(setContextSearchText(e.target.value));
  }
  }

  /**
   * returns recent searches
   * @param {void}
   * @returns {void}
   */
  const getRecenetSearches = () => {
    return ((entityName === "Linked Task")) ? <div className='no-result text-center pt-1 pb-1'>{quote.AT_LEAST_TWO_CHAR}</div> : <RecentSearch setSearchText={setSearchText} searchInputRef={searchInputRef} debouncedSearch={debouncedSearch} entityName={entityName} insertTaskChip={insertTaskChip} />
  }

  /**
   * returns search messages or searchList on the basis of search result
   * @param {void}
   * @returns {void}
   */
  const getResults = () => {
    if (noResultFound) {
      return (<><div className='no-result'>{quote.NO_RESULT_FOUND}</div></>)
    } else {
      return searchText.length < number.TWO ? (getRecenetSearches()) : 
      (
        <OverallSearchItems
          results={results}
          setShowPopup={setShowPopup}
          entityName={entityName}
          selected={selected}
          setSelected={setSelected}
          cursor={cursor}
          searchText={searchText}
          insertTaskChip={insertTaskChip}
        />)
    }
  }

  const handleChangeSearch =()=>{
setContextSearch(!contextSearch)
  }
  useCloseOnClickOutsideLocal(searchPopupRef, 'Search', setShowPopup);

  return (
    <>
      <Popup className={(entityName === "Linked Task" || entityName === editorEntity.TASK_DESC) ? 'overall-search-wrapper' : ''} show={true} anchor={anchor.current} animate={false} collision={{
        horizontal: "fit",
        vertical: "flip"
      }} popupClass={"dt-popup"} id = "dt-recent-search-list">
        <div className={popupClass + ' dt-popup-body p-0 overflow-hidden d-block border-0 overall-search-popup overall-search-inner'} ref={searchPopupRef}>
          { entityName === editorEntity.TASK_DESC ?
            <div className='d-flex justify-content-between p-3'>
              <div className='font-weight-bold'>{label.SEARCH_TASK}</div>
              <div className='small'>{label.ESC_PRESS}</div>
            </div> : <></>
          }
          <div className='overall-search-input-container position-relative'>
           {shouldShowIcon && <span onClick={handleChangeSearch}className='mr-2'>{icon.CHANGE_SEARCH}</span>}
            <Input
              type='text'
              autoFocus
              autoComplete="off"
              className='search-input'
              placeholder='Find...'
              value={searchText}
              onChange={handleOnChange}
              ref={searchInputRef}
            />
            <span className='search-popup-icon position-absolute'>{icon.SEARCH}</span>
          </div>
         {!contextSearch && <div className="overall-search-result-container overflow-y-auto">
            {getResults()}
          </div>}
          {showLoader && <div className='text-center mb-2'><Loader /></div>}
        </div>
      </Popup>
    </>
  )
}

export default OverallSearchPopup;
