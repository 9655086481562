import { Popup } from '@progress/kendo-react-popup';
import { Tooltip } from '@progress/kendo-react-tooltip';
import { addDays } from 'date-fns';
import React, { useContext, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { button, icon, label, notifyIcon, number, quote } from '../../../config';
import { getNotification } from '../../../utils/common';
import { addEditTaskRecurrence, handleTaskKeyUpdate } from '../tasks.service';
import "./recurringTask.scss";
import { createRecurrencePayload, getDatesBetweenDates, getExistingRecurrencePayload, getFrequencyDayDate, recurrenceAccess } from './recurringTaskHelper';
import { RecurringTaskContext } from './RecurringTaskContext';
import RecurrenceModesAndDurations from './RecurrenceModesAndDurations';
import RecurrenceRepeatTimes from './RecurrenceRepeatTimes';
import RecurrenceAssignment from './RecurrenceAssignment';
import RecurrenceCalendar from './RecurrenceCalendar';
import CustomRecurrence from './CustomRecurrence';

/**
 * Contains recurring task related details 
 * @author Muskan Thakur 
 */
const SaveOrEditRecurrence = (props) => {
    const { show, togglePopup, anchor, recurrPopupRef, blurTimeoutRef, onOpen, onFocus, onBlur } = props;
    const { calenderValue, recurrencePattern, recur, duration, endBydateValue, endByCountValue, skipCountValue, recurrenceMode, edit, isSaveDisabled, setIsSaveDisabled, updatedPayload, setUpdatedPayload, skipDays, assignedId, taskAssignmentType, reminderValue, frequencyInterval, frequencyIntervalType, weeklyCustomRecurDay, monthlyCustomRecurDates } = useContext(RecurringTaskContext);
    const { task, isNewTask } = useSelector((state) => state.taskSidebar);
    const { recurrenceDetails } = useSelector((state) => state.recurrence);
    const { id } = useSelector((state) => state.auth.user);
    const [popupAlign, setPopupAlign] = React.useState({
        horizontal: "right",
        vertical: "center",
    });
    const skipWeekends = skipDays.filter(function (e) { return e !== true })

    const remainingRecurrence = ((duration?.value === number.TWO || duration?.value === number.THREE) ? (duration?.value === number.THREE ? endByCountValue : (recurrencePattern === number.TWO ? null : getDatesBetweenDates(recur?.value, calenderValue, endBydateValue, skipWeekends, calenderValue, frequencyIntervalType?.value))) : null)

    const nextRecurrenceCompletionBased = addDays(calenderValue, skipCountValue)

    /**
     * sets the updated payload for recurrence and popup alignment when in edit mode
     * @author Muskan Thakur
     */
    useEffect(() => {
        if (edit) {
            setUpdatedPayload(getExistingRecurrencePayload(recurrenceDetails))
            setPopupAlign({
                horizontal: "center",
                vertical: "center",
            })
        }
    }, [edit])

    const hideOnBlur = () => {
        togglePopup(false);
    }

    /**
     * handles the process of saving or updating task recurrence details
     * @author Muskan Thakur
     */
    const handleSaveRecurrence = async () => {
        setIsSaveDisabled(true);

        const payload = createRecurrencePayload(recur, recurrencePattern, calenderValue, recurrenceMode, skipDays, duration, endBydateValue, endByCountValue, skipWeekends, reminderValue, remainingRecurrence, taskAssignmentType, task, id, skipCountValue, assignedId, frequencyInterval, frequencyIntervalType, weeklyCustomRecurDay, monthlyCustomRecurDates);

        const updatedValues = edit ? Object.fromEntries(
            Object.entries(payload)
                .filter(([key, value]) => updatedPayload[key] !== value)
        ) : null;

        if (edit) {
            updatedValues["taskId"] = task?.taskId;
            updatedValues["createdBy"] = id;
        }

        togglePopup(false);
        await addEditTaskRecurrence(edit ? updatedValues : payload);
        const frequencyDate =  getFrequencyDayDate(frequencyIntervalType, calenderValue, weeklyCustomRecurDay, monthlyCustomRecurDates)
        handleTaskKeyUpdate(isNewTask, "dueDate", frequencyDate);
    };

    /**
     * submit handler for the recurrence details 
     */
    const handleSubmit = async () => {
        if (remainingRecurrence === number.ZERO && recurrencePattern === number.ONE) return getNotification(quote.SELECT_DATES, notifyIcon.WARNING_ICON);
        if (remainingRecurrence === number.ZERO && recurrencePattern === number.TWO) return getNotification(quote.SELECT_VALID_COUNT, notifyIcon.WARNING_ICON);
        if (recurrencePattern === number.TWO && duration?.value === number.TWO && nextRecurrenceCompletionBased > endBydateValue) return getNotification(quote.SELECT_VALID_END_DATE, notifyIcon.WARNING_ICON);
        if (duration?.value && recur?.value && calenderValue && !isSaveDisabled && recurrenceAccess(task)) return handleSaveRecurrence()
    }

    return (
        <Popup
            show={show}
            anchor={anchor.current}
            animate={false}
            className="dt-popup recurring-popup"
            popupAlign={popupAlign}
            onOpen={() => onOpen(recurrPopupRef)}
        >
            <div
                className='recurring-popup-inner'
                ref={recurrPopupRef}
                tabIndex={number.ZERO}
                onFocus={() => onFocus(blurTimeoutRef)}
                onBlur={() => onBlur(blurTimeoutRef, hideOnBlur)}
            >
                <div className="dt-popup-header d-flex justify-content-between align-items-center">
                    <div className="dt-popup-title">
                        {label.TASK_RECURRENCE}
                    </div>
                    <span className="dt-popup-cancel-btn cursor-pointer" id="recurring-task-content-close-popup" onClick={() => togglePopup(false)}>
                        <Tooltip anchorElement="target" parentTitle={true} position="bottom">
                            {icon.CLOSE}
                        </Tooltip>
                    </span>
                </div>
                <div className="dt-popup-body pl-0">
                    <div className='form-row'>
                        <div className='col ml-1'></div>
                        <div className='col-md-6'>
                            <RecurrenceModesAndDurations />
                            {recur?.value === number.FIVE && <CustomRecurrence/>}
                            <RecurrenceRepeatTimes />
                            <RecurrenceAssignment />

                        </div>
                        <div className='col ml-3 border-left'></div>
                        <div className='col-md-5 set-recurrence'>
                            <RecurrenceCalendar />
                        </div>
                    </div>
                </div>
                <div className="dt-popup-footer text-right">
                    <button className="btn btn-secondary mr-2" id="recurring-task-content-cancel-recurrence" onClick={() => togglePopup(false)}>{button.CANCEL}</button>
                    <button className="btn btn-primary" id="recurring-task-content-save-recurrence" onClick={() => handleSubmit()} disabled={isSaveDisabled}>{button.SAVE}</button>
                </div>
            </div>
        </Popup>
    )
}

export default SaveOrEditRecurrence;