import React, { useEffect, useRef, useState } from "react";
import { useThemeSwitcher } from "react-css-theme-switcher";
import { connect, useDispatch, useSelector } from "react-redux";
import { useHistory, withRouter } from "react-router";
import { Link } from "react-router-dom";
import { clearAllNotes, setShowNotesList } from "../../../../actions/notes";
import Notes from "../../../../components/Notes/Notes";
import { getNotes } from "../../../../components/Notes/notes.service";
import { PROJECT_TYPE } from "../../../../components/ProjectSidebar/projectSidebar.constants";
import { button, icon, label, route } from "../../../../config";
import { number } from "../../../../config/constants";
import notes from "../../../../reducers/notes";
import { logoutUser } from "../../../../shared/services/api.service";
import { subStr } from "../../../../shared/validators/validator";
import { checkAdmin } from "../../../../utils";
import { checkSuperAdmin } from "../../../../utils/common";
import { openProjectSidebar } from "../../../services/projects.service";
import { loadState, saveState } from "../../../services/session.service";
import ChangePassword from "../ChangePassword/ChangePassword";
import "./UserDropdown.scss";

/**
 * @author Himanshi Chawla
 */

const UserDropdown = (props) => {
  const { toggleDropdown, openProfile, buttonRef } = props;
  const [showCompanyName, setShowCompanyName] = useState(null);
  const [showUserName, setShowUserName] = useState(null);
  const userDropdown = useRef(null);
  const changePassword = useRef(null);
  const { switcher, currentTheme, themes } = useThemeSwitcher();
  let theme = loadState("mode").length ? loadState("mode") : "light";
  const dispatch = useDispatch();
  const auth = useSelector((state) => state.auth);
  const history = useHistory();
  const company = useSelector((state) => state.company);
  const [notesLoading, setNotesLoading] = useState(false);
  const notes = useSelector((state) => state.notes);

  const user = auth.user;
  const organization = company.organization;

  useEffect(() => {
    setShowCompanyName(subStr(user.displayName, number.ZERO, number.NINETEEN));
    setShowUserName(subStr(user.Name, number.ZERO, number.NINETEEN));
  }, [user.id]);

  useEffect(() => {
    setShowCompanyName(subStr(user.displayName, number.ZERO, number.NINETEEN));
    setShowUserName(subStr(user.Name, number.ZERO, number.NINETEEN));
  }, [user.id]);

  /**
   * updates theme switcher
   * on toggling themes
   */
  React.useEffect(() => {
    switcher({ theme });
  }, [theme]);

  /**
   * handles theme mode of drutas
   */
  React.useEffect(() => {
    if (currentTheme == "light") {
      import("../../../../assets/themes/light-theme-variables.scss");
      import("../../../../assets/themes/light-theme.scss");
    } else if (currentTheme == "dark") {
      import("../../../../assets/themes/dark-theme-variables.scss");
      import("../../../../assets/themes/dark-theme.scss");
    }
  }, [currentTheme]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (userDropdown.current && !userDropdown.current.contains(event.target) && !buttonRef.current.contains(event.target)) {
        toggleDropdown();
      }
    };
    document.addEventListener("click", handleClickOutside);
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);

  /**
   * updates the breadcrumbs for manage organisation
   * if user propagated through superadmin console
   * @param {void}
   * @returns {void}
   * @author Pragun Gandotra
   */
  const handleClickAdminConsole = () => {
    const adminRoute = route.ADMIN_ROUTE.MANAGE_ORGANIZATION;
    if (adminRoute.PARENT_ROUTES.length > number.ONE) {
      adminRoute.PARENT_ROUTES.pop();
      adminRoute.PROPS.HEADER = "Manage Organization";
    }
    history.push(adminRoute.PATH);
    props.toggleDropdown();
  };

  /**
   * logout of app
   */
  const logOut = () => {
    dispatch(logoutUser());
  };

  /**
   * handling the open/close of NOotes dropdown and getNotes api call
   * @returns {void}
   *
   */
  const handleNotesButtonClick = async () => {
    toggleDropdown();
    setNotesLoading(true);
    await dispatch(setShowNotesList(!notes.showNotesList));
    await fetchNotes();
    setNotesLoading(false);
  };

  const fetchNotes = async () => {
    await dispatch(clearAllNotes());
    const payload = { userId: user.id, limit: number.FIFTEEN, offset: number.ZERO };
    await dispatch(getNotes(payload));
  };

  const handleLightMode = () => {
    switcher({ theme: themes.light });
    saveState("mode", "light");
  };

  const handleDarkMode = () => {
    switcher({ theme: themes.dark });
    saveState("mode", "dark");
  };

  return (
    <div id='user-dropdown-on-click' ref={userDropdown} className={`${openProfile ? `d-block` : `d-none`} header-dropdown-menu position-absolute profile-dropdown-menu right-0`}>
      <ul className='project-setting-list mt-0 pl-0 cursor-pointer'>
        <li
          className='d-inline-block w-100 cursor-pointer'
          onClick={() => {
            openProjectSidebar(PROJECT_TYPE.PERSONAL_PROJECT, auth.user.myProjectId, number.ZERO);
            props.toggleDropdown();
          }}>
          {user.Name && user.Name.trim().length >= number.NINETEEN ? (
            <span data-toggle='tooltip' data-placement='bottom' title={user.Name}>
              {label.WELCOME_USER}, {showUserName} !
            </span>
          ) : (
            <span>
              {" "}
              {label.WELCOME_USER}, {user.Name} !
            </span>
          )}
        </li>

        <li className='d-inline-block w-100 cursor-pointer'>
          <span id='user-dropdown-notes' className='cursor-pointer' disabled={notesLoading} onClick={handleNotesButtonClick}>
            {icon.EDIT_NOTES}Notes
          </span>
        </li>

        {checkAdmin(user.isAdmin) && (
          <li className='d-inline-block w-100 cursor-pointer'>
            <span id='user-dropdown-admin-console' className='cursor-pointer' onClick={handleClickAdminConsole}>
              {icon.ADMIN_SETTINGS}
              {button.ADMIN_CONSOLE}
            </span>
          </li>
        )}
        {checkSuperAdmin(user.IsSuperAdmin) && (
          <li className='d-inline-block w-100 cursor-pointer'>
            <Link to={route.SUPER_ADMIN_ROUTE.VIEW_ORGANISATION.PATH} className='text-decoration-none text-reset' onClick={() => props.toggleDropdown()}>
              <span id='user-dropdown-super-admin-console'>
                {icon.ADMIN_SETTINGS}
                {button.SUPER_ADMIN_CONSOLE}
              </span>
            </Link>
          </li>
        )}
        <li className='bg-transparent pb-0'>Theme</li>
        <span className='d-inline-block w-100 cursor-pointer'>
          <li className='d-inline-block w-100 cursor-pointer'>
            <span id='user-dropdown-light-mode' className='cursor-pointer' onClick={handleLightMode}>
              {icon.LIGHT_MODE}Light Mode
            </span>
          </li>
          <li className='d-inline-block w-100 cursor-pointer'>
            <span id='user-dropdown-dark-mode' className='cursor-pointer' onClick={handleDarkMode}>
              {icon.DARK_MODE}Dark Mode
            </span>
          </li>
        </span>
        <div class='dropdown-divider mb-0 opacity-1 overflow-hidden'></div>
        <li className='d-inline-block w-100 cursor-pointer'>
          <span onClick={logOut} id='log-out'>
            {icon.LOGOUT} {button.LOGOUT}
          </span>
        </li>
      </ul>
      <Notes />
    </div>
  );
};

export default UserDropdown;
