import React, { useEffect, useRef, useState } from 'react';
import { PanelBar, PanelBarItem } from "@progress/kendo-react-layout";
import { navbarOptions } from "../../Tasks/tasks.constants";
import { useDispatch, useSelector } from 'react-redux';
import { setExpanded, setTempTaskDesc } from "../../../actions/taskSidebar";
import { ActivityLog, Comments, TaskDetail, Attachments, TaskDescription, LinkedSubTasks, ApprovalDescription } from "./index";
import ParentTaskDetailPanel from './ParentTaskDetailPanel/ParentTaskDetailPanel';
import _ from 'lodash';
import DescriptionPopup from './Description/DescriptionPopup';
import Approvals from './Approvals/Approvals';
import { handleDescSection } from '../sidebar.helper';
import WorkflowElementsPanel from './WorkflowElementsPanel/WorkflowElementsPanel';
import "./TaskSidebarContent.scss"
import { setShowApprovalLogs, setShowParentTaskDescription, setShowTaskDetailPanel } from '../../../actions/sidebarContent';
import CustomPanelBarItemContent from './CustomPanelBarItemContent';
import TaskComments from './Comments/TaskComments';
import TransferOwnershipPopup from './TransferOwnershipPopup';
/**
 * TaskSidebarContent component
 * @props {}
 */
const TaskSidebarContent = () => {
  const dispatch = useDispatch();
  const { expanded, task, currentFocus, showDescPopup, isNewTask, newTaskData , showTransferOwnershipPopup} = useSelector((state) => state.taskSidebar);
  const { showSimplifiedDetail } = useSelector((state) => state.taskSidebar)
  const { defaultDetails } = useSelector((state) => state.tasks)
  // tempDesc is used for holding the current desc value.
  const [tempDesc, setTempDesc] = useState(null);
  const { user } = useSelector((state) => state.auth);

  const {showTaskDetailPanel, showApprovalLogs, showDescription} = useSelector((state) => state.sidebarContent);
  /*
 * Parent Component: TaskSidebar
 * ShowTaskDetailPanel
 * @author Muskan
 */

  useEffect(() => {
    dispatch(setShowTaskDetailPanel(showSimplifiedDetail))
    dispatch(setShowApprovalLogs(showSimplifiedDetail))
  }, [showSimplifiedDetail])

  useEffect(() => {
    let desc;
    if (isNewTask) {
      desc = newTaskData?.description ? newTaskData?.description : '';
    } else {
      desc = task?.Description ? task?.Description : '';
    }
    setTempDesc(showDescPopup?.status ? desc : null)
    dispatch(setTempTaskDesc(desc))
  }, [showDescPopup?.status])

  /**
   * Sets expanded state of PanelBar on initial render
   * @author Himanshu Negi
   */
  useEffect(() => {
    const expandedItems = navbarOptions?.filter((option) => option.expandCondition());
    let result = expandedItems.map((ele) => ele.id);
    dispatch(setExpanded(_.uniq([...result])));
  }, [task?.parentLinkTaskCount, newTaskData?.parentLinkTaskCount, newTaskData?.attachmentCount, newTaskData?.childOrSubtaskCount, currentFocus, task?.ShowLockedDescription]);

  const handleSelect = (event) => {
    if(event.target.props.id==="task-details") dispatch(setShowTaskDetailPanel(!showTaskDetailPanel))
    if(event.target.props.id==="parent-task-details") dispatch(setShowParentTaskDescription(!showDescription))
    if(event.target.props.id==="task-approval") dispatch(setShowApprovalLogs(!showApprovalLogs));
    if(event.target.props.id==="task-workflow") return dispatch(setExpanded(event.expandedItems));
    if (event.expandedItems) {
      handleDescSection(event.expandedItems);
      dispatch(setExpanded(event.expandedItems));
    }
  };

  /**
   * Returns component on the basis of expanded state item
   * @param {String} id
   * @returns {JSX.Element}
   * @author Himanshu Negi
   */
  const getComponent = (id) => {
    switch (id) {
      case 'task-details':
        return <TaskDetail/>
      case 'task-description':
        return <TaskDescription/>
      case 'parent-task-details':
        return <ParentTaskDetailPanel/>
      case 'task-attachments':
        return <Attachments />;
      case 'task-comments':
        return <TaskComments />
      case 'task-workflow':
        return <WorkflowElementsPanel/>
      case 'task-activity-log':
        return <ActivityLog />
      case 'task-linked-tasks':
        return <LinkedSubTasks />
      case 'task-approval':
        return <Approvals/>
      case 'task-approval-description':
        return <ApprovalDescription />
    }
  }

  return (
    <div>
      {navbarOptions.map((option, idx) => {
        const show = option.renderCondition(option.render);
        const label = (option.label instanceof Function) ? option.label() : option.label;
        return <PanelBar
          key={idx}
          isControlled={true}
          expanded={expanded}
          onSelect={handleSelect}
          className={label == 'Details' ? (showTaskDetailPanel ? "simplfified-task-detail-panel" : "") :  (label=='Approval' ? (showApprovalLogs ? "simplfified-approval-panel" : "") : (!showDescription ? "simplfified-parent-task-panel" : ""))}
          expandMode={(label == 'Details' || label == 'Parent Task Details' ||  label=='Approval') ? "none" : ""}
        >
          <PanelBarItem
            key={idx.toString()}
            id={option.id}
            title={
              <CustomPanelBarItemContent
                title={label}
                showTaskDetailPanel={showTaskDetailPanel}
              />
            }
            className={!show ? "d-none" : ""}
          >
            {defaultDetails?.isAdded && show && getComponent(option.id)}
          </PanelBarItem>
        </PanelBar>
        
      })
      }
      {showDescPopup.status && <DescriptionPopup/>}
      
    </div>
  )
};
export default TaskSidebarContent;
