import React, { useEffect, useMemo, useState } from 'react';
import { icon } from '../../config';
import { useEditorContext } from '../editor.Context';
import UrlDialog from '../editorTools/GroupedMediaTools/UrlDialog';
import PDFDialog from '../editorTools/GroupedMediaTools/PDFDialog';
import { getIframeProp, processString } from '../editorTools/GroupedMediaTools/groupedMedia-utils';
import ExcalidrawDialog from '../editorTools/ExcalidrawDialog';

/**
* Update IFrame 
* PC KendoEditor
* @author Shivam Mishra
*/
const UpdateIFrame = ({editorRef}) => {   
    const editorState = useEditorContext();
    const { editIframeId, editIframe , setEditFrame , setEditFrameId , setVisibleDialog  } = editorState ; 
    const [iframeProp , setIframeProp] = useState() ; 
    const [extractedWord, extractedName] = useMemo(() => {
      if (editIframeId) {
        return processString(editIframeId);
      } else {
        return [null, null];
      }
    }, [editIframeId]);

/**
 * Effect to set iframe properties when extractedName changes.
 * @author Shivam
 */
useEffect(() => {
  if (extractedName) setIframeProp(getIframeProp(editIframeId, extractedName)); 
}, [extractedName]);

/**
 * Updates an iframe node in the editor with new properties.
 * @author Shivam
 */
const updateIframe = (params) => {
  const { src, width , height, id } = params;  

  if (editorRef.current) {
    const { view } = editorRef.current.state;
    const { state, dispatch } = view;
    const { schema, doc, tr } = state;

    doc.descendants((node, pos) => {
      if (node.type.name === 'iframe' && node.attrs.id === id) {
        const updatedNode = schema.nodes.iframe.create(
          {
            ...node.attrs,
            src: src, 
            style: `width: ${width}vw; height: ${height}vh;`,
          }
        );

        // Replace the old node with the new node
        const transaction = tr.replaceWith(
          pos,
          pos + node.nodeSize,
          updatedNode
        );
        dispatch(transaction);
      }
    });
  }
};

/**
 * removes an iframe node in the editor with provided id of  iframe
 * @author Shivam
 */
const removeIframe = (params) => {
  const id  = params;  

  if (editorRef.current) {
    const { view } = editorRef.current.state;
    const { state, dispatch } = view;
    const { doc, tr } = state;

    // Traverse the document to find the iframe with the given id
    doc.descendants((node, pos) => {
      if (node.type.name === 'iframe' && node.attrs.id === id) {
        
        // Create a transaction that deletes the node
        const transaction = tr.delete(
          pos,
          pos + node.nodeSize
        );
        
        // Dispatch the transaction to update the document
        dispatch(transaction);
      }
    });
  }
  setEditFrameId(null) ;
  setEditFrame(false) ; 
  setVisibleDialog(false) ; 
};


/**
 * Renders the appropriate dialog based on the extracted word.
 * @param {Function} updateIframe - Function to update the iframe.
 * @author Shivam
 */
const renderDialog = () => {
  const dialogs = {
    "url": <UrlDialog setDialog={setEditFrame} props={editorRef.current.state} updateNode={true} iframeProp={iframeProp} updateIframe={updateIframe} removeIframe={removeIframe}/>,
    "media": <PDFDialog setDialog={setEditFrame} props={editorRef.current.state} updateNode={true} iframeProp={iframeProp} updateIframe={updateIframe} removeIframe={removeIframe}/> , 
    "canvas" : <ExcalidrawDialog onClick={() => {setEditFrame(!editIframe)}} view={editorRef.current.state.view} updateNode={true} updateIframe={updateIframe} iframeProp={iframeProp}/>
  };
  return dialogs[extractedWord] || null;
};


    return (
        <div>
            {<div onClick={() => {setEditFrame(!editIframe)}} className='d-flex justify-content-center align-items-center mr-2 cursor-pointer d-block'>{icon.EDIT_ICON}</div>}
            {editIframe && renderDialog()}
        </div>
    );
};

export default React.memo(UpdateIFrame);