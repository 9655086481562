import { matchPath } from "react-router";
import { format, startOfDay } from "date-fns";
import { isEmpty } from "lodash";
import { alertNotification } from "../../actions/alertNotification";
import { setKanbanTasks } from "../../actions/kanbanUpdates";
import { showTaskSidebarOnTop } from "../../actions/latestSidebar";
import { addRelationData, storeChildTask, storeParentTask, storeRelatedTask, storeSubTask } from "../../actions/linkedTasks";
import { resetPlanMyday } from "../../actions/planMyDay";
import { addNewStream, setIsLastStream, storeEmojiIcons, storeProjectStreams, updateProjectDesc } from "../../actions/projects";
import {
  setNewMyTasks,
  setProjectOwner,
  setTaskApiCallCompleted,
  setTaskrecurrence,
  storeArchivedCount,
  storeDefaultTaskValues,
  storeTasks,
  updateProjectTaskAction,
  updateTaskAction,
} from "../../actions/task";
import {
  getFocusedSection,
  resetRecurrence,
  resetSidebarReducer,
  setApprovalLogs,
  setDescriptionPopupSetting,
  setExpanded,
  setFlowElements,
  setLoader,
  setNewTaskData,
  setShowAttachments,
  setShowComments,
  setShowDescription,
  setShowLinkedTasksSubTasks,
  setShowSimplfiedDetail,
  setShowTaskSidebar,
  setSidebarLoadingState,
  setStages,
  setTask,
  setTaskOnBlur,
  storeIncompleteSubtaskCount,
  storeLinkTaskCount,
  storeRecurrence,
  storeRecurrenceDefaults,
  taskDescLogSent,
  toggleDescPopup,
  toggleSidebarLoader,
  updateDescModeStore,
  updateFollowerIds,
} from "../../actions/taskSidebar";
import { getUserDetails } from "../../actions/userProfile";
import { label, notifyIcon, number, quote, requestMethod, route } from "../../config";
import config from "../../env.config";
import { getDateAtMidnight, lowercaseKeys } from "../../helper/common";
import { appendFieldsFromObject, createFormData } from "../../helper/formDataHelper";
import { fetch } from "../../shared/services/api.service";
import store from "../../store/index";
import { compareLists, getNotification, isTrueBit } from "../../utils/common";
import { checkApiSuccess } from "../../utils/index";
import { compareInitialTasks, getGroupedTasks } from "../KanbanView/kanban.helper";
import { updateKanbanView } from "../KanbanView/kanban.service";
import { getQueueTasks } from "../Queues/QueueTasks/queue.service";
import { saveEntityRecentHistory } from "../RecentHistory/recentHistory.helper";
import { filterTaskSearchList } from "../TaskSidebar/TaskSidebarContent/LinkedSubTasks/LinkedTasks/linkTaskSearch.helper";
import { getCurrentActiveProject, getKey, getUpdatePayload, mapTaskKey } from "../TaskSidebar/sidebar.common";
import { getNextCustomRecurForMonth, getNextCustomRecurForWeek, recurrenceAccess } from "./RecurringTask/recurringTaskHelper";
import { getReminder } from "./Reminders/reminder.service";
import { updateTaskField } from "./task.service.helper";

export const getNewTaskId = () => {
  return async (dispatch) => {
    const requestConfig = {
        method: requestMethod.GET,
        url: `${config.BASE_URL.BASE_API_URL}/task/getNewTaskId`,
      },
      response = await fetch(requestConfig, true, false);
    if (checkApiSuccess(response)) {
      store.dispatch(showTaskSidebarOnTop());
      store.dispatch(setShowTaskSidebar({ showTaskSidebar: true, isNewTask: true }));
      store.dispatch(resetRecurrence());
      handleTaskKeyUpdate(true, "id", response.data.data.Id);
    }
  };
};

/**
 * divides tasks of a user in personal and project tasks for my task page
 * @param {Array} data all tasks of a user
 * @returns {Object} tasks result
 * @author {Prachi Jain}
 */
export const getMyTasks = (data) => {
  const state = store.getState();
  const { user } = state.auth;
  let tasks = {
    myTasks: data?.filter((t) => t.ProjectName === user.myProjectName),
    projectTasks: data?.filter((t) => t.ProjectName !== user.myProjectName),
  };
  return { ...tasks };
};

export const getUserTasks = (payload, isFilterSearched) => {
  const state = store.getState();
  const { userId, isArchived, limit, offset } = payload;
  const {
    stages,
    assignee,
    priority,
    tags,
    tagsOr,
    dateRangeFromDate,
    dateRangeFromDay,
    dateRangeFrom,
    dateRangeToDate,
    dateRangeTo,
    dateRangeTaskDue,
    dateRangeTaskDueDays,
    approvals,
    ownership,
    workflows,
    isCompleted,
    isOverdue,
    isOpen,
    filterName,
    excludeDueDate,
  } = state.teamFilter.teamFilterData;
  const { lastFilterId } = state.teamFilter;
  const { positionId } = state.userPosition;

  return async (dispatch) => {
    let requestConfig = {
      method: requestMethod.POST,
      url: `${config.BASE_URL.BASE_API_URL}/task/getUserTasks`,
      data: isFilterSearched
        ? {
            isArchived,
            userId,
            offset,
            limit,
            stages: stages ? stages.join() : null,
            assignee: assignee ? assignee.join() : null,
            priority: priority ? priority.join() : null,
            tags: tags ? tags.join() : null,
            tagsOr,
            approvals: approvals ? approvals.join() : null,
            ownership: ownership ? ownership.join() : null,
            workflows: workflows ? workflows.join() : null,
            isCompleted: isCompleted ?? false,
            isOverdue,
            isOpen: isOpen ?? false,
            name: filterName,
            positionId,
            dateRangeFromDate,
            dateRangeFromDay,
            dateRangeFrom,
            dateRangeToDate,
            dateRangeTo,
            dateRangeTaskDue,
            dateRangeTaskDueDays,
            excludeDueDate,
          }
        : {
            userId: userId,
            isArchived: isArchived,
            limit: limit,
            offset: offset,
            positionId,
            filterId: lastFilterId,
          },
    };
    let response = await fetch(requestConfig, true, false);

    if (checkApiSuccess(response)) {
      let myTasks = response.data.data.tasks;
      let tasks = getMyTasks(myTasks);
      tasks.superset = tasks.myTasks?.concat(tasks.projectTasks);
      dispatch(storeTasks(tasks));
      if (isArchived && offset > number.ZERO) dispatch(setTaskApiCallCompleted(true));
    }
  };
};

export const getProjectTasks = (payload, isFilterSearched) => {
  const state = store.getState();
  const { projectId, userId, isArchived, offset, limit, positionId } = payload;
  const {
    stages,
    assignee,
    priority,
    tags,
    tagsOr,
    dateRangeFromDate,
    dateRangeFromDay,
    dateRangeFrom,
    dateRangeToDate,
    dateRangeTo,
    dateRangeTaskDue,
    dateRangeTaskDueDays,
    approvals,
    ownership,
    workflows,
    isCompleted,
    isOverdue,
    isOpen,
    filterName,
    excludeDueDate,
  } = state.teamFilter.teamFilterData;
  const { lastFilterId } = state.teamFilter;
  const { selectedView } = state.tasks;
  const { groupBy, kanbanTasks } = state.kanbanUpdates;
  return async (dispatch) => {
    let requestConfig = {
      method: requestMethod.POST,
      url: `${config.BASE_URL.BASE_API_URL}/task/getProjectTasks`,
      data: isFilterSearched
        ? {
            projectId,
            isArchived,
            userId,
            offset,
            limit,
            stages: stages ? stages.join() : null,
            assignee: assignee ? assignee.join() : null,
            priority: priority ? priority.join() : null,
            tags: tags ? tags.join() : null,
            tagsOr,
            approvals: approvals ? approvals.join() : null,
            ownership: ownership ? ownership.join() : null,
            workflows: workflows ? workflows.join() : null,
            isCompleted: isCompleted ?? false,
            isOverdue,
            isOpen: isOpen ?? false,
            name: filterName,
            positionId,
            dateRangeFromDate,
            dateRangeFromDay,
            dateRangeFrom,
            dateRangeToDate,
            dateRangeTo,
            dateRangeTaskDue,
            dateRangeTaskDueDays,
            excludeDueDate,
          }
        : {
            projectId,
            isArchived,
            userId,
            offset,
            limit,
            positionId,
            filterId: lastFilterId,
          },
    };
    let response = await fetch(requestConfig, true, false);
    if (checkApiSuccess(response)) {
      const { tasks } = response.data.data;
      tasks.superset = tasks.myTasks?.concat(tasks.projectTasks);
      dispatch(storeTasks(tasks));
      dispatch(setProjectOwner(tasks?.projectMembers));
      if (selectedView === label.KANBAN) {
        const kanbanData = compareInitialTasks(kanbanTasks, tasks?.projectTasks);
        const tasksData = getGroupedTasks(kanbanData, groupBy);
        dispatch(setKanbanTasks(tasksData));
        updateKanbanView({ projectId, userId, tasks: tasksData });
      }
      if (isArchived && offset > number.ZERO) dispatch(setTaskApiCallCompleted(true));
    }
  };
};

export const getDefaultTaskValues = (companyId, userId, projectId, removeLoader) => {
  const state = store.getState();
  const { loaderComponent } = state.taskSidebar;
  let loader = loaderComponent[removeLoader];
  return async (dispatch) => {
    dispatch(toggleSidebarLoader({ showLoader: false }));
    const requestConfig = {
        method: requestMethod.GET,
        url: `${config.BASE_URL.BASE_API_URL}/task/getDefaultTaskValues`,
        params: {
          companyId: companyId,
          userId: userId,
          projectId: projectId,
        },
      },
      response = await fetch(requestConfig, loader ? false : true, false);
    if (checkApiSuccess(response)) {
      dispatch(storeDefaultTaskValues({ ...response.data.data, isAdded: true }));
    }
  };
};

/**
 * Handles sidebar field updates based on new or existing task
 * @param {Bool} isNewTask
 * @param {String} key
 * @param {Any} value
 */
export const handleTaskKeyUpdate = async (isNewTask, key, value) => {
  if (isNewTask) await handleNewTaskUpdate(key, value);
  else {
    let res = await handleExistingTaskUpdate(key, value);
    if (res && key == "dueDate") handleTaskKeyUpdate(false, "reminderCount", res?.reminderCount);
    return res;
  }
};

/**
 * Handles reducer for new task open in sidebar
 * @param {String} key
 * @param {Any} value
 */
const handleNewTaskUpdate = async (key, value) => {
  const state = store.getState(),
    newTaskData = state.taskSidebar.newTaskData;
  if (newTaskData[key] !== value) {
    await store.dispatch(setNewTaskData({ value, key }));
  }
};

/**
 * Handles reducer and backend update on existing task open in sidebar
 * @param {String} key
 * @param {Any} value
 */
const handleExistingTaskUpdate = async (key, value) => {
  let state = store.getState(),
    { task } = state.taskSidebar;
  const taskKey = mapTaskKey(key);
  if (taskKey && task[taskKey?.value] !== value && ((taskKey.emptyCheck && !isEmpty(value)) || !taskKey.emptyCheck)) {
    await store.dispatch(setTaskOnBlur({ value: value, key: taskKey?.value }));
    if (task.taskId && taskKey?.automaticUpdate) {
      let defaultPayload = getUpdatePayload();
      const response = await store.dispatch(updateTaskSidebar({ ...defaultPayload, ...{ [key]: value } }));
      return response;
    }
  }
};

/**
 * opens the task sidebar and accordian items on the basis of expandedItems array.
 * @param {Object} props: {taskId,userId,taskHistoryId}
 * @param {Integer} expandedItem
 * @returns
 */
export const openSidebar = async (props, isPlanMyDay, sidebarLoader, signal) => {
  await store.dispatch(setShowTaskSidebar({ showTaskSidebar: true }));
  store.dispatch(setExpanded([]));
  store.dispatch(showTaskSidebarOnTop());
  !isPlanMyDay && store.dispatch(resetPlanMyday());
  let taskData;
  if (props.id) {
    store.dispatch(setSidebarLoadingState(true));
    store.dispatch(getFocusedSection(""));
    try {
      await store.dispatch(getTaskWorkflowDetails({ taskId: props.id }, signal));
      taskData = await store.dispatch(getTaskDetails({ taskId: props.id, userId: props.userId, taskHistoryId: props.taskHistoryId, entityProjectId: props.entityProjectId }, sidebarLoader, signal));
      store.dispatch(setSidebarLoadingState(false));
    } catch (error) {
      store.dispatch(setSidebarLoadingState(false));
    }
    saveEntityRecentHistory(props.id, "Tasks");
    if (taskData?.taskDetails && !taskData.access && !taskData?.taskDetails?.taskId) {
      store.dispatch(setShowTaskSidebar({ showTaskSidebar: false }));
      getNotification(quote.TASK_ACCESS_DENIED, notifyIcon.WARNING_ICON);
    } else if (taskData) {
      await store.dispatch(setTask(taskData?.taskDetails));
      await store.dispatch(setApprovalLogs(taskData?.approvalLogs));
    }
  }
};

/**
 * handles clone/reset based recurrence for a task
 * @author Muskan Thakur
 */
export const handleRecurrenceTasks = async (payload) => {
  const state = store.getState();
  const { allTasks } = state.tasks;
  const { id } = state.auth.user;
  const { task, flowElements, taskAttachments } = state.taskSidebar;
  const currentTask = allTasks?.superset?.find((task) => task.Id === payload?.taskId);
  let selectedTask = isEmpty(task) ? currentTask : task;
  const { currentProject, currentProjectIndex } = getCurrentActiveProject(flowElements);
  const [, ...flowElementsExceptFirst] = state.taskSidebar.flowElements;

  /**
   * existing flow elements for the task
   */
  let taskflowElementsForRecurrence = "";
  if (state.recurrence.recurrenceDetails?.TaskAssignmentType === number.FIVE && state.recurrence.recurrenceDetails?.RecurrenceMode === number.ONE && flowElementsExceptFirst?.length) {
    taskflowElementsForRecurrence = flowElementsExceptFirst?.map((obj) => obj.id).join(",");
  } else {
    taskflowElementsForRecurrence = "";
  }

  /**filters non duplicate attachments for the task */
  const filterdAttachments = state.recurrence?.workflowAttachments.filter(
    (workflowAttachment) => !taskAttachments.some((taskAttachment) => taskAttachment.AttachmentName === workflowAttachment.AttachmentName)
  );

  if (
    (!isTrueBit(selectedTask, "IsCompleted") || isTrueBit(task, "InApproval")) &&
    (currentProject?.assignmentProjectId === task?.EntityProjectId || currentProject?.isApproval) &&
    currentProjectIndex === flowElements?.length - 1 &&
    (task?.taskId ? state.recurrence.recurrenceDetails?.Id : selectedTask.recurrenceId)
  ) {
    if ((task?.taskId ? state.recurrence.recurrenceDetails?.RecurrenceMode : selectedTask.recurrenceMode) === 1) {
      await createRecurrenceTask({
        taskId: payload.taskId,
        RecurrenceMode: state.recurrence.recurrenceDetails?.RecurrenceMode,
        projectId: selectedTask.CurrentProject,
        taskHistoryId: selectedTask.taskHistoryId,
        isReturnable: selectedTask?.isReturnable,
        userId: id,
        flowElements: taskflowElementsForRecurrence,
        recurrenceflowElements: state.recurrence.recurrenceflowElements?.length ? state.recurrence.recurrenceflowElements : [],
        recurrenceOnset: state.recurrence.recurrenceDetails?.RecurrenceOnset,
        nextRecurrence: state.recurrence.recurrenceDetails && getNextRecurrence(state.recurrence.recurrenceDetails),
      });
      if (filterdAttachments?.length) {
        const payload = { entityName: "Tasks", entityId: task?.taskId, files: [], copyAttachments: JSON.stringify(filterdAttachments) };
        const formData = createFormData();
        appendFieldsFromObject(formData, payload);
        store.dispatch(newTaskAttachments(formData));
      }
      store.dispatch(resetRecurrence());
    } else {
      await recurrenceCloneTask({
        taskId: payload.taskId,
        userId: id,
      });
    }
  }
};

export const addTask = async (task) => {
  const state = store.getState();
  const { id } = state.auth.user;
  const { newTaskData } = state.taskSidebar;
  const requestConfig = {
      method: requestMethod.POST,
      url: `${config.BASE_URL.BASE_API_URL}/task/addTask`,
      data: task,
    },
    response = await fetch(requestConfig, false, false);
  if (checkApiSuccess(response)) {
    saveEntityRecentHistory(response.data.data.EntityId, "Tasks");
    await store.dispatch(quickFilterCardsCount(id, newTaskData?.project));
    return response.data.data;
  }
};

export const addAttachment = async (payload) => {
  const requestConfig = {
      method: requestMethod.POST,
      url: `${config.BASE_URL.BASE_API_URL}/app/addAttachment`,
      data: payload,
    },
    response = await fetch(requestConfig, false, false);
  if (checkApiSuccess(response)) {
    return response.data.data;
  }
};

export const deleteAttachment = async (payload) => {
  const requestConfig = {
      method: requestMethod.POST,
      url: `${config.BASE_URL.BASE_API_URL}/app/deleteAttachment`,
      data: payload,
    },
    response = await fetch(requestConfig, false, false);
  if (checkApiSuccess(response)) {
    return response.data.success;
  }
};

export const retractTask = async (payload) => {
  const requestConfig = {
      method: requestMethod.POST,
      url: `${config.BASE_URL.BASE_API_URL}/task/retractTask`,
      data: payload,
    },
    response = await fetch(requestConfig, false, false);
  if (checkApiSuccess(response)) {
    return response.data.data;
  }
};

export const rejectTask = async (payload) => {
  const requestConfig = {
      method: requestMethod.POST,
      url: `${config.BASE_URL.BASE_API_URL}/task/rejectTask`,
      data: payload,
    },
    response = await fetch(requestConfig, false, false);
  if (checkApiSuccess(response)) {
    return response.data.data;
  }
};

export const getTasks = (offset, forceUpdate, isFilterSearched) => {
  const state = store.getState();
  const { id } = state.auth.user;
  const { archivedView } = state.tasks;
  const { lastFilterId, teamSavedFilters } = state.teamFilter;
  const teamUserSavedFilter = teamSavedFilters?.find((filter) => filter.value === lastFilterId);
  const { positionId } = state.userPosition;
  let pathname = window.location.pathname;
  let matchPathRoute = matchPath(pathname, { path: route.PRIVATE_ROUTE.TASKS.ROUTER_PATH });
  const queueMatchPath = matchPath(pathname, { path: route.PRIVATE_ROUTE.QUEUE_TASKS.ROUTER_PATH });
  if (queueMatchPath) {
    store.dispatch(getQueueTasks(queueMatchPath.params.queueId, null, isFilterSearched));
    return;
  }

  if ((matchPathRoute && teamUserSavedFilter?.label) || forceUpdate) {
    if (matchPathRoute?.params?.projectId) {
      if (archivedView || teamUserSavedFilter?.label == label.ARCHIVED) {
        store.dispatch(
          getProjectTasks(
            { projectId: matchPathRoute?.params?.projectId, isArchived: archivedView, userId: id, limit: number.TWENTY, offset: offset ? offset : number.ZERO, positionId },
            isFilterSearched
          )
        );
      } else store.dispatch(getProjectTasks({ projectId: matchPathRoute?.params?.projectId, isArchived: archivedView, userId: id, positionId }, isFilterSearched));
    } else {
      if (archivedView || teamUserSavedFilter?.label == label.ARCHIVED) {
        store.dispatch(getUserTasks({ userId: id, isArchived: archivedView, limit: number.TWENTY, offset: offset ?? number.ZERO }, isFilterSearched));
      } else store.dispatch(getUserTasks({ userId: id, idArchived: archivedView }, isFilterSearched));
    }
  }
};

export const completeTask = async (payload) => {
  const state = store.getState();
  const { allTasks } = state.tasks;
  const { task } = state.taskSidebar;
  const currentTask = allTasks?.superset?.find((task) => task.Id === payload?.taskId);
  let selectedTask = isEmpty(task) ? currentTask : task;

  const requestConfig = {
      method: requestMethod.POST,
      url: `${config.BASE_URL.BASE_API_URL}/task/completeTask`,
      data: payload,
    },
    response = await fetch(requestConfig, false, false);

  if (checkApiSuccess(response)) {
    !isTrueBit(selectedTask, "IsCompleted") &&
      (task?.taskId ? state.recurrence.recurrenceDetails?.Id : selectedTask.recurrenceId) &&
      recurrenceAccess(selectedTask) &&
      (await createRecurrenceTask({ taskId: payload.taskId, projectId: selectedTask.CurrentProject, taskHistoryId: selectedTask.taskHistoryId, isReturnable: selectedTask?.isReturnable }));
    getTasks(null, true);
    return response.data.data;
  }
};

export const completeAndForward = async (payload) => {
  const state = store.getState();
  const { id } = state.auth.user;
  const { CurrentProject } = state.taskSidebar.task;
  const requestConfig = {
    method: requestMethod.POST,
    url: `${config.BASE_URL.BASE_API_URL}/task/completeAndForward`,
    data: payload,
  };

  const response = await fetch(requestConfig, false, false);

  if (checkApiSuccess(response)) {
    const { data } = response.data;
    await store.dispatch(quickFilterCardsCount(id, CurrentProject));
    getNotification(label.TASK_COMPLETED, notifyIcon.SUCCESS_ICON);
    handleRecurrenceTasks(payload);
    return data;
  }
};

export const deleteTask = async (taskId, userId) => {
  const state = store.getState();
  const { id } = state.auth.user;
  const { CurrentProject } = state.taskSidebar.task;
  const requestConfig = {
      method: requestMethod.GET,
      url: `${config.BASE_URL.BASE_API_URL}/task/deleteTask`,
      params: {
        taskId,
        userId,
      },
    },
    response = await fetch(requestConfig, true, false);
  if (checkApiSuccess(response)) {
    await store.dispatch(quickFilterCardsCount(id, CurrentProject));
    return response.data.data;
  }
};

export const archiveTask = async (payload) => {
  const requestConfig = {
      method: requestMethod.PUT,
      url: `${config.BASE_URL.BASE_API_URL}/task/archiveTask`,
      params: payload,
    },
    response = await fetch(requestConfig, false, false);
  if (checkApiSuccess(response)) {
    getNotification(response.data.data, notifyIcon.SUCCESS_ICON);
    return response.data.data;
  }
};

export const cloneTask = async (payload) => {
  let requestConfig = {
    method: requestMethod.POST,
    url: `${config.BASE_URL.BASE_API_URL}/task/cloneTask`,
    data: payload,
  };
  let response = await fetch(requestConfig, false, false);
  if (checkApiSuccess(response)) {
    return response.data.data;
  }
};

export const acceptTask = async (payload) => {
  const requestConfig = {
      method: requestMethod.POST,
      url: `${config.BASE_URL.BASE_API_URL}/task/acceptTask`,
      data: payload,
    },
    response = await fetch(requestConfig, false, false);
  if (checkApiSuccess(response)) {
    getNotification(response.data.data, notifyIcon.SUCCESS_ICON);
    return response.data.data;
  }
};

export const getAttachments = async (entityName, entityId) => {
  let requestConfig = {
    method: requestMethod.GET,
    url: `${config.BASE_URL.BASE_API_URL}/app/getAttachments`,
    params: {
      entityName: entityName,
      entityId: entityId,
    },
  };
  let response = await fetch(requestConfig, false, false);
  if (checkApiSuccess(response)) {
    return response.data.data;
  }
};

/**
 * Checks the type of access user has to task
 * @param {userId, projectId, taskId} params
 * @returns
 */
export const verifyTaskAccess = async (params) => {
  const requestConfig = {
      method: requestMethod.GET,
      url: `${config.BASE_URL.BASE_API_URL}/task/verifyTaskAccess`,
      params: params,
    },
    response = await fetch(requestConfig, true, false);
  if (checkApiSuccess(response)) {
    return response.data.data;
  }
};

/**
 * Add Nudge API
 * @param  { userId, taskId, taskHistoryId } payload
 * @returns
 */
export const nudge = (payload) => {
  return async (dispatch) => {
    const requestConfig = {
        method: requestMethod.POST,
        url: `${config.BASE_URL.BASE_API_URL}/task/nudge`,
        data: payload,
      },
      response = await fetch(requestConfig, true, false);
    if (checkApiSuccess(response)) {
      getNotification(payload.nudgeId ? quote.NUDGE_ACKNOWLEDGED : quote.NUDGE_SENT, notifyIcon.SUCCESS_ICON);
      getTasks(null, true);
      return response.data.data ?? {};
    }
  };
};

export const addUpdateCustomFilter = (payload) => {
  return async (dispatch) => {
    const requestConfig = {
        method: requestMethod.POST,
        url: `${config.BASE_URL.BASE_API_URL}/task/customtask`,
        data: payload.data,
      },
      response = await fetch(requestConfig, false, false);
    if (checkApiSuccess(response)) {
      return response.data.data;
    }
  };
};

export const getTaskDetails = (params, sidebarLoader, signal) => {
  return async (dispatch) => {
    sidebarLoader && dispatch(toggleSidebarLoader(true));
    const requestConfig = {
        method: requestMethod.GET,
        url: `${config.BASE_URL.BASE_API_URL}/task/getTaskDetails`,
        params: params,
        signal,
      },
      response = await fetch(requestConfig, false, false);
    sidebarLoader && dispatch(toggleSidebarLoader(false));
    if (checkApiSuccess(response)) {
      return response.data.data;
    }
  };
};

export const updateTaskSidebar = (payload, reset) => {
  const state = store.getState();
  const { archivedView } = state.tasks;
  return async (dispatch) => {
    dispatch(setLoader(getKey(payload)));
    const requestConfig = {
        method: requestMethod.POST,
        url: `${config.BASE_URL.BASE_API_URL}/task/newUpdateTask`,
        data: payload,
      },
      response = await fetch(requestConfig, false, false);
    dispatch(setLoader(getKey(payload)));
    if (checkApiSuccess(response)) {
      if (reset) {
        dispatch(setShowTaskSidebar(false));
        dispatch(resetSidebarReducer());
      }
      return response?.data?.data;
    } else {
      getNotification(label.ERROR_OCCURED, notifyIcon.ERROR_ICON);
      dispatch(setShowTaskSidebar(false));
      dispatch(resetSidebarReducer());
    }
  };
};

export const updateTaskStage = async (payload) => {
  const requestConfig = {
      method: requestMethod.POST,
      url: `${config.BASE_URL.BASE_API_URL}/task/newUpdateTask`,
      data: payload,
    },
    response = await fetch(requestConfig, false, false);
  if (checkApiSuccess(response)) {
    return response.data.data;
  }
};

export const addUpdateProjectDescription = (payload) => {
  return async (dispatch) => {
    const requestConfig = {
        method: requestMethod.POST,
        url: `${config.BASE_URL.BASE_API_URL}/project/addUpdateProjectDescription`,
        data: payload,
      },
      response = await fetch(requestConfig, true, false);
    if (checkApiSuccess(response)) {
      dispatch(updateProjectDesc(payload.projectDescription));
      return response.data.data;
    }
  };
};

export const addUpdateStream = (payload) => {
  return async (dispatch) => {
    const requestConfig = {
        method: requestMethod.POST,
        url: `${config.BASE_URL.BASE_API_URL}/project/addUpdateStream`,
        data: payload,
      },
      response = await fetch(requestConfig, false, false);
    if (checkApiSuccess(response)) {
      dispatch(addNewStream(response.data.data));
    }
  };
};

export const getStream = (payload) => {
  return async (dispatch) => {
    const requestConfig = {
        method: requestMethod.GET,
        url: `${config.BASE_URL.BASE_API_URL}/project/getStream`,
        params: payload,
      },
      response = await fetch(requestConfig, true, false);
    if (checkApiSuccess(response)) {
      response.data.data?.stream?.length ? dispatch(storeProjectStreams(response.data.data.stream)) : dispatch(setIsLastStream(true));
      dispatch(storeEmojiIcons(response.data.data.emojiList));
    }
    return response.data.data;
  };
};

export const getEntityDescription = (entityId, entityName, userId) => {
  return async (dispatch) => {
    const requestConfig = {
        method: requestMethod.GET,
        url: `${config.BASE_URL.BASE_API_URL}/common/getEntityDescription`,
        params: { entityId, entityName, userId },
      },
      response = await fetch(requestConfig, true, false);
    if (checkApiSuccess(response)) {
      dispatch(updateProjectDesc(response.data.data.Description));
      return response.data.data.Description;
    }
  };
};

export const updateStreamReaction = (payload) => {
  return async () => {
    const requestConfig = {
      method: requestMethod.POST,
      url: `${config.BASE_URL.BASE_API_URL}/project/updateStreamReaction`,
      data: payload,
    };
    await fetch(requestConfig, false, false);
  };
};

export const deleteStream = (payload) => {
  return async () => {
    const requestConfig = {
      method: requestMethod.POST,
      url: `${config.BASE_URL.BASE_API_URL}/project/deleteStream`,
      data: payload,
    };
    await fetch(requestConfig, false, false);
  };
};

/**
 * handling api for getting all activity logs
 * @returns {void}
 * @author Prachi Jain
 */

export const getLinkTasks = (payload) => {
  return async (dispatch) => {
    const state = store.getState();
    const { childTask } = state.linkedTasks.linkTask;
    const { isNewTask } = state.taskSidebar;
    dispatch(toggleSidebarLoader(true));
    const requestConfig = {
      method: requestMethod.GET,
      url: `${config.BASE_URL.BASE_API_URL}/linkedTask/getLinkTask`,
      params: {
        taskId: payload.taskId,
        userId: payload.userId,
      },
    };
    const response = await fetch(requestConfig, false, false);
    dispatch(toggleSidebarLoader(false));
    if (checkApiSuccess(response)) {
      const responseData = response.data.data;
      filterTaskSearchList(payload.taskId, responseData);
      dispatch(storeChildTask(responseData?.linkedTasks?.childOfTasks));
      dispatch(storeParentTask(responseData?.linkedTasks?.parentOfTasks));
      dispatch(storeRelatedTask(responseData?.linkedTasks?.relatedToTasks));
      dispatch(storeSubTask(responseData?.linkedTasks?.subTasks));
      return response.data.data;
    }
  };
};

export const deleteOrCompleteSubtask = (payload) => {
  return async (dispatch) => {
    dispatch(toggleSidebarLoader(true));
    const requestConfig = {
      method: requestMethod.DELETE,
      url: `${config.BASE_URL.BASE_API_URL}/linkedTask/deleteOrCompleteSubtask`,
      data: payload,
    };
    const response = await fetch(requestConfig, false, false);
    dispatch(toggleSidebarLoader(false));
    if (checkApiSuccess(response)) {
      dispatch(alertNotification(true, response.data.message, notifyIcon.SUCCESS_ICON));
      dispatch(storeIncompleteSubtaskCount(response.data.data.inCompleteSubtaskCount));
    }
  };
};

export const convertSubtaskToTask = (payload) => {
  return async (dispatch) => {
    const requestConfig = {
      method: requestMethod.POST,
      url: `${config.BASE_URL.BASE_API_URL}/linkedTask/convertSubtaskToTask`,
      data: payload,
    };
    dispatch(toggleSidebarLoader({ showLoader: true }));
    const response = await fetch(requestConfig, false, false);
    dispatch(toggleSidebarLoader({ showLoader: false }));
    if (checkApiSuccess(response)) {
      dispatch(alertNotification(true, response?.data?.message, notifyIcon.SUCCESS_ICON));
      return response.data.data?.linkTask[0];
    }
  };
};

/**
 * Get Relation filter Data.
 * @param {string} data
 * @returns Relation Id, Relation Name
 * @author Aniket Gupta
 */

export const getRelationData = () => {
  return async (dispatch) => {
    const requestPayload = {
      method: requestMethod.GET,
      url: `${config.BASE_URL.BASE_API_URL}/linkedTask/getRelationData`,
    };
    const response = await fetch(requestPayload, false, false);
    if (checkApiSuccess(response)) {
      dispatch(addRelationData(response.data.data));
      return response.data.data;
    }
  };
};

/**
 * Handling add link task
 * @param {string} data
 * @returns Msg
 * @author Aniket Gupta
 */
export const addLinkTask = (payload) => {
  return async (dispatch) => {
    const requestConfig = {
      method: requestMethod.POST,
      url: `${config.BASE_URL.BASE_API_URL}/linkedTask/addLinkTask`,
      data: payload,
    };
    const response = await fetch(requestConfig, false, false);
    if (checkApiSuccess(response)) {
      store.dispatch(alertNotification(true, response.data.message, notifyIcon.SUCCESS_ICON));
      dispatch(storeLinkTaskCount(response.data.data));
      return response.data.data.linkTask[0];
    }
  };
};

export const addSubTask = (payload) => {
  return async (dispatch) => {
    const requestConfig = {
      method: requestMethod.POST,
      url: `${config.BASE_URL.BASE_API_URL}/linkedTask/addSubtask`,
      data: payload,
    };
    const response = await fetch(requestConfig, false, false);
    if (checkApiSuccess(response)) {
      store.dispatch(alertNotification(true, response.data.message, notifyIcon.SUCCESS_ICON));
      dispatch(storeIncompleteSubtaskCount(response.data.data[1][0].inCompleteSubtaskCount));
      return response.data.data[0][0];
    }
  };
};

/**
 * Handling delete link task
 * @param {string} data
 * @returns Msg
 * @author Aniket Gupta
 */
export const deleteLinkTasks = (payload) => {
  return async (dispatch) => {
    const requestConfig = {
      method: requestMethod.DELETE,
      url: `${config.BASE_URL.BASE_API_URL}/linkedTask/deleteLinkTasks`,
      data: payload,
    };
    const response = await fetch(requestConfig, false, false);
    if (checkApiSuccess(response)) {
      dispatch(alertNotification(true, response.data.message, notifyIcon.WARNING_ICON));
      dispatch(storeLinkTaskCount(response.data.data));
      dispatch(storeIncompleteSubtaskCount(response.data.data.inCompleteSubtaskCount));
    }
  };
};

export const addOrganizationTag = async (payload) => {
  store.dispatch(toggleSidebarLoader({ showLoader: false }));
  const requestConfig = {
    method: requestMethod.POST,
    url: `${config.BASE_URL.BASE_API_URL}/task/addOrganizationTag`,
    data: payload,
  };
  const response = await fetch(requestConfig, false, false);
  store.dispatch(toggleSidebarLoader({ showLoader: false }));
  if (checkApiSuccess(response)) {
    return response.data.data;
  }
};

export const updateUserDuedateFilter = async (payload) => {
  const state = store.getState();
  const { archivedView } = state.tasks;
  const requestConfig = {
    method: requestMethod.POST,
    url: `${config.BASE_URL.BASE_API_URL}/task/updateUserDuedateFilter`,
    data: payload,
  };
  const response = await fetch(requestConfig, false, false);
  if (checkApiSuccess(response)) {
    await store.dispatch(getUserDetails(payload.userId));
    archivedView ? getTasks(number.ZERO, null) : getTasks(null, true);
    store.dispatch(alertNotification(true, response.data.message, notifyIcon.SUCCESS_ICON));
  }
};

export const cloneOrNewTaskAttachment = async (payload) => {
  const requestConfig = {
      method: requestMethod.POST,
      url: `${config.BASE_URL.BASE_API_URL}/task/cloneTaskAttachment`,
      data: payload,
    },
    response = await fetch(requestConfig, false, false);
  if (checkApiSuccess(response)) {
    return response.data.data;
  }
};

export const newTaskAttachments = (payload) => {
  return async (dispatch) => {
    let requestConfig = {
      method: requestMethod.POST,
      url: `${config.BASE_URL.BASE_API_URL}/task/newTaskAttachments`,
      data: payload,
    };
    let response = await fetch(requestConfig, false, false);
    if (checkApiSuccess(response)) {
      return response.data.data;
    }
  };
};

export const addLinkedAndSubtasks = (payload) => {
  return async () => {
    const requestConfig = {
        method: requestMethod.POST,
        url: `${config.BASE_URL.BASE_API_URL}/linkedTask/addLinkedAndSubtasks`,
        data: payload,
      },
      response = await fetch(requestConfig, true, false);
    if (checkApiSuccess(response)) {
      return response.data.data;
    }
  };
};

export const updateSubtask = async (payload) => {
  const requestConfig = {
    method: requestMethod.PUT,
    url: `${config.BASE_URL.BASE_API_URL}/linkedTask/updateSubtask`,
    data: payload,
  };
  const response = await fetch(requestConfig, false, false);
  if (checkApiSuccess(response)) {
    return response;
  }
};

export const removeFollowerUser = (payload) => {
  const state = store.getState();
  const { isNewTask } = state.taskSidebar;
  if (!isNewTask) {
    return async (dispatch) => {
      const requestConfig = {
          method: requestMethod.DELETE,
          url: `${config.BASE_URL.BASE_API_URL}/task/removeFollower`,
          data: payload,
        },
        response = await fetch(requestConfig, false, false);
      if (checkApiSuccess(response)) {
        dispatch(updateFollowerIds(response.data.data?.followerIds));
      }
    };
  }
};

export const changeLinkedTaskOrder = (payload) => {
  return async () => {
    const requestConfig = {
      method: requestMethod.POST,
      url: `${config.BASE_URL.BASE_API_URL}/linkedTask/changeLinkedTaskOrder`,
      data: payload,
    };
    await fetch(requestConfig, false, false);
  };
};

export const changeSubTaskOrder = (payload) => {
  return async () => {
    const requestConfig = {
      method: requestMethod.PUT,
      url: `${config.BASE_URL.BASE_API_URL}/linkedTask/changeSubTaskOrder`,
      data: payload,
    };
    await fetch(requestConfig, false, false);
    store.dispatch(getLinkTasks(payload));
  };
};

export const getLinkTaskDetails = async (payload) => {
  store.dispatch(toggleSidebarLoader(true));
  const requestConfig = {
    method: requestMethod.GET,
    url: `${config.BASE_URL.BASE_API_URL}/linkedTask/getLinkTaskDetails`,
    params: {
      linkTaskId: payload.linkTaskId,
      relationId: payload.relationId,
    },
  };
  const response = await fetch(requestConfig, false, false);
  store.dispatch(toggleSidebarLoader(false));
  if (checkApiSuccess(response)) {
    return response.data.data;
  }
};

export const pinTasks = async (payload) => {
  const requestConfig = {
      method: requestMethod.POST,
      url: `${config.BASE_URL.BASE_API_URL}/task/pinUserTasks`,
      data: payload,
    },
    response = await fetch(requestConfig, false, false);
  if (checkApiSuccess(response)) {
    return response.data.data;
  }
};

/**
 * api call for task approvals,
 * handles recurrence in approval tasks
 * @param {Object} payload
 * @returns
 * @author Himanshu Negi
 */
export const addTaskApprovalsHistory = (payload, notification, projectId) => {
  const state = store.getState();
  const { archivedView } = state.tasks;
  const { id } = state.auth.user;
  const { task } = state.taskSidebar;
  const { recurrenceDetails } = state.recurrence;
  const { positionId } = state.userPosition;
  const { user } = state.auth;
  return async (dispatch) => {
    const requestConfig = {
        method: requestMethod.POST,
        url: `${config.BASE_URL.BASE_API_URL}/task/addTaskApprovalsHistory`,
        data: { ...payload, positionId },
      },
      response = await fetch(requestConfig, false, false);
    if (checkApiSuccess(response)) {
      if (payload.approvalStatus === "Approved" && recurrenceDetails?.Id) {
        const updatedTask = { ...task, recurrenceId: recurrenceDetails?.Id };
        await dispatch(setTask(updatedTask));
        handleRecurrenceTasks(payload);
      }
      (await archivedView) ? getTasks(number.ZERO, null) : getTasks(null, true);
      getNotification(notification, notifyIcon.SUCCESS_ICON);
      dispatch(resetSidebarReducer());
      await dispatch(quickFilterCardsCount(id, projectId ?? user.myProjectId));
      return response.data.data;
    }
  };
};

export const deleteApprovalActivity = (payload) => {
  const state = store.getState();
  const { archivedView } = state.tasks;
  const { user } = state.auth;
  return async (dispatch) => {
    const requestConfig = {
        method: requestMethod.DELETE,
        url: `${config.BASE_URL.BASE_API_URL}/task/deleteApprovalActivity`,
        data: { ...payload, userId: user.id },
      },
      response = await fetch(requestConfig, false, false);
    if (checkApiSuccess(response)) {
      (await archivedView) ? getTasks(number.ZERO, null) : getTasks(null, true);
      await openSidebar({ id: payload?.taskId, userId: user.id, taskHistoryId: null }, null, true);
    }
  };
};

/**
 * api call for editAttachmentName
 * @param {Object} payload
 * @returns
 */

export const editAttachmentName = async (payload) => {
  const requestConfig = {
      method: requestMethod.POST,
      url: `${config.BASE_URL.BASE_API_URL}/app/renameAttachment`,
      data: payload,
    },
    response = await fetch(requestConfig, false, false);
  if (checkApiSuccess(response)) {
    return response.data.success;
  }
};

export const addEditTaskRecurrence = async (payload) => {
  const requestConfig = {
      method: requestMethod.POST,
      url: `${config.BASE_URL.BASE_API_URL}/task/addTaskRecurrence`,
      data: payload,
    },
    response = await fetch(requestConfig, true, true);
  if (checkApiSuccess(response)) {
    let res = await getReminder(payload.createdBy, payload.taskId);
    let recurrence = response.data.data;
    handleTaskKeyUpdate(false, "reminderCount", res?.length);
    store.dispatch(storeRecurrence(recurrence));
    store.dispatch(setTaskrecurrence(payload.taskId, recurrence?.Id, recurrence?.RecurrenceMode));
    return response.data.data;
  }
};

export const getTaskRecurrence = async (payload) => {
  const requestPayload = {
    method: requestMethod.GET,
    url: `${config.BASE_URL.BASE_API_URL}/task/getTaskRecurrence`,
    params: {
      taskId: payload.taskId,
    },
  };
  const response = await fetch(requestPayload, true, true);
  if (checkApiSuccess(response)) {
    let recurrence = response.data.data[0][0];
    store.dispatch(storeRecurrence(recurrence));
    store.dispatch(setTaskrecurrence(payload.taskId, recurrence?.Id, recurrence?.RecurrenceMode));
    return response.data.data;
  }
};

export const deleteTaskRecurrence = async (payload) => {
  const state = store.getState();
  const { id } = state.auth.user;
  const requestConfig = {
      method: requestMethod.DELETE,
      url: `${config.BASE_URL.BASE_API_URL}/task/deleteTaskRecurrence`,
      data: payload,
    },
    response = await fetch(requestConfig, false, true);
  if (checkApiSuccess(response)) {
    await getTaskRecurrence({ taskId: payload.taskId });
    let res = await getReminder(id, payload.taskId);
    handleTaskKeyUpdate(false, "reminderCount", res?.length);
    return response.data.data;
  }
};

export const createRecurrenceTask = async (payload) => {
  const requestConfig = {
      method: requestMethod.PUT,
      url: `${config.BASE_URL.BASE_API_URL}/task/createRecurrenceTask`,
      data: payload,
    },
    response = await fetch(requestConfig, true, true);
  if (checkApiSuccess(response)) {
    getNotification(quote.MAC_RECURRENCE, notifyIcon.SUCCESS_ICON);
    return response.data.data;
  }
};

/**
 * handles recurrence clone success
 * @author Muskan Thakur
 */
export const handleRecurrenceCloneSuccess = async (data) => {
  const state = store.getState();
  const { id } = state.auth.user;
  const { workflowAttachments, recurrenceflowElements, recurrenceDetails } = state.recurrence;
  const [, ...flowElementsExceptFirst] = state.taskSidebar.flowElements;

  /**
   * if wf based assignment then wf flow elements else previous task's flow elements
   */
  let recurrenceflowElementsForClone = [];
  if (recurrenceDetails?.TaskAssignmentType === number.FIVE) {
    if (recurrenceflowElements?.length) {
      recurrenceflowElementsForClone = recurrenceflowElements;
    }
  } else {
    if (flowElementsExceptFirst?.length) {
      recurrenceflowElementsForClone = flowElementsExceptFirst;
    }
  }
  await createRecurrenceTask({
    taskId: data.Id,
    projectId: data.CurrentProject,
    taskHistoryId: data.TaskHistoryId,
    isReturnable: true,
    userId: id,
    flowElements: "",
    recurrenceflowElements: recurrenceflowElementsForClone,
    nextRecurrence: state.recurrence.recurrenceDetails && getNextRecurrence(state.recurrence.recurrenceDetails),
  });
  if (workflowAttachments && recurrenceDetails?.TaskAssignmentType === number.FIVE) {
    const payload = { entityName: "Tasks", entityId: data.Id, files: [], copyAttachments: JSON.stringify(workflowAttachments) };
    const formData = createFormData();
    appendFieldsFromObject(formData, payload);
    store.dispatch(newTaskAttachments(formData));
  }
  store.dispatch(resetSidebarReducer());
  store.dispatch(resetRecurrence());
  openSidebar({ id: data.Id, userId: id });
};

/**
 * Clones a task with recurrence and updates the respective task fields accordingly
 * @param {Object}
 * @author Muskan Thakur
 */
export const recurrenceCloneTask = async (payload) => {
  const requestConfig = {
    method: requestMethod.POST,
    url: `${config.BASE_URL.BASE_API_URL}/task/recurrenceCloneTask`,
    data: payload,
  };
  const response = await fetch(requestConfig, true, true);
  if (checkApiSuccess(response)) {
    const data = response.data.data;
    getNotification(quote.MAC_RECURRENCE, notifyIcon.SUCCESS_ICON);
    handleRecurrenceCloneSuccess(data);
    return data;
  }
};

export const getRecurrenceDefaults = (payload) => {
  return async () => {
    let requestConfig = {
      method: requestMethod.GET,
      url: `${config.BASE_URL.BASE_API_URL}/task/getRecurrenceDefaults`,
      params: { userId: payload.userId },
    };
    let response = await fetch(requestConfig, true, true);
    if (checkApiSuccess(response)) {
      store.dispatch(storeRecurrenceDefaults(response.data.data));
      return response.data.data;
    }
  };
};

/**
 * api call for addViewedStream
 * @param {Object} payload
 */
export const addViewedStream = (payload) => {
  return async () => {
    const requestConfig = {
      method: requestMethod.POST,
      url: `${config.BASE_URL.BASE_API_URL}/project/addViewedStreams`,
      data: payload,
    };
    await fetch(requestConfig, false, false);
  };
};

export const getTaskWorkflowDetails = (params, signal) => {
  return async (dispatch, getState) => {
    const requestConfig = {
        method: requestMethod.GET,
        url: `${config.BASE_URL.BASE_API_URL}/task/getTaskWorkflowDetails`,
        params: params,
        signal,
      },
      response = await fetch(requestConfig, false, false);
    if (checkApiSuccess(response)) {
      const taskWorkflowDetails = response.data.data;
      const { taskStages, taskflowElements, taskVisibilityOptions } = taskWorkflowDetails;
      dispatch(setStages(taskStages));
      const { taskSidebar } = getState();
      if (
        !compareLists(
          taskSidebar?.flowElements,
          taskflowElements?.map((item) => lowercaseKeys(item))
        )
      ) {
        dispatch(setFlowElements(taskflowElements?.map((item) => lowercaseKeys(item))));
      }
      if (taskVisibilityOptions) {
        const { ShowDescription, ShowAttachments, ShowComments, ShowChildSubTasks, ShowSimplifiedDetail, ShowTaskDescPopup } = taskVisibilityOptions;
        dispatch(setShowDescription(ShowDescription));
        dispatch(setShowAttachments(ShowAttachments));
        dispatch(setShowComments(ShowComments));
        dispatch(setShowLinkedTasksSubTasks(ShowChildSubTasks));
        dispatch(setShowSimplfiedDetail(ShowSimplifiedDetail));
        dispatch(setDescriptionPopupSetting(!!ShowTaskDescPopup));
      }
      return response.data.data;
    }
  };
};

export const addTaskPanelVisibiltyOptions = async (payload) => {
  const requestConfig = {
      method: requestMethod.POST,
      url: `${config.BASE_URL.BASE_API_URL}/task/addTaskPanelVisibiltyOptions`,
      data: payload,
    },
    response = await fetch(requestConfig, true, true);
  if (checkApiSuccess(response)) {
    return response.data.data;
  }
};

export const editTaskPanelVisibiltyOptions = async (payload) => {
  const requestConfig = {
      method: requestMethod.PUT,
      url: `${config.BASE_URL.BASE_API_URL}/task/editTaskPanelVisibiltyOptions`,
      data: payload,
    },
    response = await fetch(requestConfig, true, true);
  if (checkApiSuccess(response)) {
    return response.data.data;
  }
};

export const addNewComments = (payload) => {
  return async (dispatch) => {
    let requestConfig = {
      method: requestMethod.POST,
      url: `${config.BASE_URL.BASE_API_URL}/comment/addNewComments`,
      data: payload,
    };
    let response = await fetch(requestConfig, false, false);
    if (checkApiSuccess(response)) {
      return response.data.data;
    }
  };
};

export const reopenTask = async (payload) => {
  let requestConfig = {
    method: requestMethod.PUT,
    url: `${config.BASE_URL.BASE_API_URL}/task/reopenTask`,
    data: payload,
  };
  let response = await fetch(requestConfig, null, false);
  if (checkApiSuccess(response)) {
    return response.data.data;
  }
};

/**
 * Checks the type of access user has to task
 * @param {userId, projectId, taskId} params
 * @returns
 */
export const deleteFile = async (payload) => {
  const requestConfig = {
      method: requestMethod.POST,
      url: `${config.BASE_URL.BASE_API_URL}/comment/deleteFile`,
      data: payload,
    },
    response = await fetch(requestConfig, true, false);
  if (checkApiSuccess(response)) {
    return response.data.data;
  }
};

/**
 * Checks the type of access user has to task
 * @param {userId, projectId, taskId} params
 * @returns
 */
export const getNewCount = async (payload) => {
  const requestConfig = {
      method: requestMethod.GET,
      url: `${config.BASE_URL.BASE_API_URL}/comment/getCount`,
      params: payload,
    },
    response = await fetch(requestConfig, true, false);
  if (checkApiSuccess(response)) {
    return response.data.data;
  }
};

export const getProjectStages = async (payload) => {
  const requestConfig = {
      method: requestMethod.GET,
      url: `${config.BASE_URL.BASE_API_URL}/project/getProjectStages`,
      params: payload,
    },
    response = await fetch(requestConfig, true, false);
  if (checkApiSuccess(response)) {
    return response.data.data;
  }
};

export const updateUserPosition = async (payload) => {
  const requestConfig = {
      method: requestMethod.POST,
      url: `${config.BASE_URL.BASE_API_URL}/user/updateUserPosition`,
      data: payload,
    },
    response = await fetch(requestConfig, true, false);
  if (checkApiSuccess(response)) {
    return response.data.data;
  }
};

/**
 * gives quick filters count values for the respective project
 * @param {Number} userId
 * @param {Number} projectId
 * @author Muskan Thakur
 */
export const quickFilterCardsCount = (userId, projectId) => {
  return async (dispatch) => {
    const requestConfig = {
        method: requestMethod.GET,
        url: `${config.BASE_URL.BASE_API_URL}/task/quickFilterCardsCount`,
        params: {
          projectId,
          userId,
        },
      },
      response = await fetch(requestConfig, false, false);
    if (checkApiSuccess(response)) {
      dispatch(storeArchivedCount(response.data.data));
    }
  };
};

export const getTaskInfoForDescription = async (taskId, cancelTokenSource) => {
  const requestConfig = {
    method: requestMethod.GET,
    url: `${config.BASE_URL.BASE_API_URL}/task/getTaskInfoForDescription`,
    params: { taskId: taskId },
    cancelToken: cancelTokenSource.token,
  };

  const response = await fetch(requestConfig, false, false);

  if (checkApiSuccess(response)) {
    return response?.data?.data;
  }
};

export const getPlannedTask = async (userId) => {
  const requestConfig = {
      method: requestMethod.GET,
      url: `${config.BASE_URL.BASE_API_URL}/task/getPlannedTask`,
      params: { userId },
    },
    response = await fetch(requestConfig, true, false);
  if (checkApiSuccess(response)) {
    return response.data.data;
  }
};

export const markTaskAsPlanned = async (payload) => {
  const requestConfig = {
      method: requestMethod.POST,
      url: `${config.BASE_URL.BASE_API_URL}/task/markAsPlanned`,
      data: payload,
    },
    response = await fetch(requestConfig, true, false);
  if (checkApiSuccess(response)) {
    return response.data.data;
  }
};

export const updateDescMode = (payload) => {
  return async (dispatch) => {
    const requestConfig = {
      method: requestMethod.PUT,
      url: `${config.BASE_URL.BASE_API_URL}/task/updateDescMode`,
      data: payload,
    };
    const response = await fetch(requestConfig, false, false);
    if (checkApiSuccess(response)) {
      dispatch(updateDescModeStore(payload));
    }
  };
};

/**
 * service function to call addUpdateDescLog api
 * to add update desc log in task when description is changed
 * @author {Pragun Gandotra}
 */
export const addUpdateDescLog = (payload, descPopup) => {
  return async (dispatch) => {
    const requestConfig = {
      method: requestMethod.PUT,
      url: `${config.BASE_URL.BASE_API_URL}/task/addUpdateDescLog`,
      data: payload,
    };
    const response = await fetch(requestConfig, false, false);
    if (checkApiSuccess(response)) {
      if (!descPopup) dispatch(taskDescLogSent());
      return true;
    }
  };
};

/**
 * gives the next recurrence for the respective recurrence type
 * @param {Object} recurrenceDetails
 * @author Muskan Thakur
 */
const getNextRecurrence = (recurrenceDetails) => {
  const { FrequencyIntervalType, FrequencyDayDate, WeeklyCustomRecurDay, FrequencyInterval, MonthlyCustomRecurDates } = recurrenceDetails;

  let updateFrequencyDayDate =  getDateAtMidnight(FrequencyDayDate)
  if (FrequencyIntervalType === number.TWO) {
    return getNextCustomRecurForWeek(updateFrequencyDayDate, WeeklyCustomRecurDay.split(",").map(Number), FrequencyInterval);
  }

  if (FrequencyIntervalType === number.THREE) {
    const formattedMonthlyDates = MonthlyCustomRecurDates.split(",").map((date) => date.trim());
    const formattedStartDate = format(updateFrequencyDayDate, "yyyy-MM-dd");
    return getNextCustomRecurForMonth(formattedMonthlyDates, formattedStartDate, FrequencyInterval);
  }

  return null;
};
