import { Tooltip } from "@progress/kendo-react-tooltip";
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { makeExistingTaskDescChanged, setExpanded, toggleDescPopup } from '../../../../actions/taskSidebar';
import { icon, label, number } from '../../../../config/constants';
import KendoEditor from '../../../../editor/KendoEditor';
import { editorToolType } from '../../../../editor/editor.Constant';
import { handleTaskKeyUpdate } from '../../../Tasks/tasks.service';
import { DESC_REGX, DESC_REGX_FOR_EMPTY_TEXT, DESC_TYPE } from '../../sidebar.constants';
import "./description.scss";


/**
 * Common Component for approved task & normal task description
 * Parent component => TaskApproval, ApprovalDescription
 * @param {Object} props
 * @author Himanshu Negi
 * @returns JSX
 */
const Descriptions = (props) => {
  const { desc, setDesc, taskKey, descriptionText, type, viewRef,autoSaveKey, setUpdateAutoSaveKey , 
    editableRef, editorRef, editorEditIcon , isEditable , setIsEditable} = props;

  const { task, isNewTask, expanded, showDescription, newTaskData } = useSelector((state) => state.taskSidebar);
  const { taskId } = task;
  const dispatch = useDispatch();


  useEffect(() => {
    if (isNewTask && showDescription ){
      setDesc(newTaskData?.description ?? "")
    } 
  }, [newTaskData?.description, showDescription])

  useEffect(() => {
    if (task && !isNewTask && autoSaveKey === number.ONE) {
      setDesc(descriptionText);
    }
  }, [taskId, descriptionText])

  /**
  * updates description when auto save key gets updated
  */
  useEffect(() =>{
    autoSaveKey > number.ONE && updateDescription(desc)
  } , [autoSaveKey])

  /**
   * updates description and removes empty paragaraphs if there is no content present in the description box
   */
  const updateDescription = async (nextValue) => {
    if (nextValue) handleTaskKeyUpdate(isNewTask, taskKey, nextValue?.replace(DESC_REGX, '') ? nextValue : nextValue?.replace(DESC_REGX_FOR_EMPTY_TEXT, ''));
    type === DESC_TYPE.DESCRIPTION && isNewTask && handleTaskKeyUpdate(true, 'isDescChanged', true);
    !isNewTask && type === DESC_TYPE.DESCRIPTION && dispatch(makeExistingTaskDescChanged());
  }
  /**
   * It open description popup and closes the description section on TaskSidebar
   * @param {Void}
   * @returns {Void}
   * @author Himanshu Negi
   */
  const handleDescPopup = (type) => {
    const newExpand = type === DESC_TYPE.DESCRIPTION ? expanded.filter((item) => item !== "task-description") : expanded.filter((item) => item !== "task-approval-description")
    dispatch(toggleDescPopup({ status: true, type }))
    dispatch(setExpanded(newExpand));
  }

  /**
  * Toggle Editable state and update description if editable
  * @param None
  * @return None
  * @author Shivam Mishra
  */
  const toggleEditable = async () => {
    if (isEditable) { updateDescription(desc) }
    setIsEditable(!isEditable);
    editableRef.current = !isEditable;
  }

  return (
    <React.Fragment>
      <Tooltip anchorElement="target" position="bottom" parentTitle='true'>
        <button onClick={() => handleDescPopup(type)}
          className={`description-popup-icon position-absolute bg-transparent d-inline-flex tour-description-popup-icon ${type === DESC_TYPE.APPROVAL_DESCRIPTION ? "approved-text" : "desc-text"}`}
          title={label.POP_OUT} data-testid="description-popup-icon">{icon.OPEN_IN_FULL}</button>
      </Tooltip>
      <div className='task-description-editor'>
        <KendoEditor
          desc={desc}
          setDesc={setDesc}
          viewRef={viewRef}
          editableRef={editableRef}
          toggleEditable={toggleEditable}
          editorRef={editorRef}
          editorEditIcon={editorEditIcon}
          autoSaveKey = {autoSaveKey} 
          setUpdateAutoSaveKey = {setUpdateAutoSaveKey}
          toolbar={editorToolType.TASK_SIDEBAR_TOOL}
        />
      </div>
    </React.Fragment>
  );
}

export default Descriptions;
