import { Calendar } from '@progress/kendo-react-dateinputs';
import { Popup } from '@progress/kendo-react-popup';
import { Tooltip } from '@progress/kendo-react-tooltip';
import React, { useContext } from 'react';
import { useSelector } from 'react-redux';
import { icon, label, number } from '../../../config';
import { isDateValid } from '../../../shared/validators/validator';
import { deleteTaskRecurrence } from '../tasks.service';
import "./recurringTask.scss";
import { generateAssigneeLabel, recurrenceAccess } from './recurringTaskHelper';
import { RecurringTaskContext } from './RecurringTaskContext';
import RecurrenceCustomCalendarCell from './RecurrenceCustomCalendarCell';

/**
 * kendo calander cell custom component 
 */
const CalendarCustomCell = ({ props, realProps, recur, duration, calenderValue, endBydateValue, endByCountValue, skipDays, recurrencePattern, skipCountValue, disablePastDueDate, frequencyInterval, frequencyIntervalType, weeklyCustomRecurDay, setCalenderValue={setCalenderValue}, monthlyCustomRecurDates }) => <RecurrenceCustomCalendarCell {...props} realProps={realProps} recur={recur} duration={duration} calenderValue={calenderValue} endBydateValue={endBydateValue} endByCountValue={endByCountValue} skipDays={skipDays} recurrencePattern={recurrencePattern} skipCountValue={skipCountValue} disablePastDueDate={disablePastDueDate} frequencyInterval={frequencyInterval} frequencyIntervalType={frequencyIntervalType} weeklyCustomRecurDay={weeklyCustomRecurDay} setCalenderValue={setCalenderValue} monthlyCustomRecurDates={monthlyCustomRecurDates}
/>

/**
 * Contains recurring task related details 
 * @author Muskan Thakur 
 */
const SavedRecurrence = (props) => {
    const { show, togglePopup, anchor, recurrPopupRef, blurTimeoutRef, onOpen, onFocus, onBlur } = props;
    const { calenderValue, recurrencePattern, recur, title, duration, endBydateValue, endByCountValue, skipCountValue, recurrDisabled, setRecurrDisabled, setEdit, project, recurrenceCreator, skipDays, frequencyInterval, frequencyIntervalType, weeklyCustomRecurDay, setCalenderValue, monthlyCustomRecurDates, workflows, taskAssignmentType, assignedId } = useContext(RecurringTaskContext);
    const { task } = useSelector((state) => state.taskSidebar);
    const { recurrenceDetails } = useSelector((state) => state.recurrence);
    const [popupAlign, setPopupAlign] = React.useState({
            horizontal: "right",
            vertical: "center",
        });
    const hideOnBlur = () => {
        togglePopup(false);
    }

    /**
     * deletes the applied recurrence
     */
    const handleRecurDisableChange = () => {
        setRecurrDisabled(!recurrDisabled)
        togglePopup(false)
        deleteTaskRecurrence({ taskId: task?.taskId })
    }

    return (
        <Popup
            show={show}
            anchor={anchor.current}
            animate={false}
            popupAlign={popupAlign}
            className="recurring-popup recurring-popup-edit"
            onOpen={() => onOpen(recurrPopupRef)}
        >
            <div
                ref={recurrPopupRef}
                tabIndex={number.ZERO}
                onFocus={() => onFocus(blurTimeoutRef)}
                onBlur={() => onBlur(blurTimeoutRef, hideOnBlur)}
            >
                <div className="text-center recurring-heading enabled-recurrence pt-2 pb-2">{title}</div>
                <div className="text-center">
                    <Calendar value={calenderValue}
                        focusedDate={recurrenceDetails && isDateValid(recurrenceDetails?.FrequencyDayDate) && new Date(recurrenceDetails?.FrequencyDayDate)}
                        navigation={false}
                        cell={((props) => <CalendarCustomCell realProps={props} {...props} recur={recur} duration={duration} calenderValue={calenderValue} endBydateValue={endBydateValue} endByCountValue={endByCountValue} skipDays={skipDays} recurrencePattern={recurrencePattern} skipCountValue={skipCountValue} frequencyInterval={frequencyInterval} frequencyIntervalType={frequencyIntervalType} weeklyCustomRecurDay={weeklyCustomRecurDay} setCalenderValue={setCalenderValue} monthlyCustomRecurDates={monthlyCustomRecurDates}/>)}
                    />
                </div>
                <label className='form-section-heading text-nowrap mt-2 pl-3 pr-3'>{`${label.CREATED_BY} ${recurrenceCreator} in ${project}`}</label>
                <label className='form-section-heading text-wrap mt-2 pl-3 pr-3'>{generateAssigneeLabel(assignedId?.value, taskAssignmentType?.value, workflows, project)}</label>
                {recurrenceAccess(task) && <Tooltip anchorElement="target" parentTitle={true} position="bottom"><div className='d-flex justify-content-center align-content-between mt-2 mb-2 pl-3 pr-3'>
                    <div onClick={() => handleRecurDisableChange()} title={label.DEL_REC}  className="delete-button cursor-pointer red w-100 text-left" id='recurring-task-content-delete-recurrence' >{icon.DELETE_ICON}</div>
                    <div onClick={() => setEdit(true)} title={label.EDIT_REC}  className="edit-icon cursor-pointer blue w-100 text-right" id='recurring-task-content-edit-recurrence'>{icon.EDIT_ICON}</div>
                </div></Tooltip>}
            </div>
        </Popup>
    )
}

export default SavedRecurrence;