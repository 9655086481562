import React from 'react';

import { icon, label, notifyIcon, number, quote, tooltip } from "../../config";
import { splitString } from '../../utils';
import { editWorkflow } from "../../shared/services/workflow.services";
import store from "../../store";
import { getNotification } from "../../utils/common";
import { PROJECT_TYPE } from '../ProjectSidebar/projectSidebar.constants';
import { openProjectSidebar } from '../../shared/services/projects.service';
import { updateProjectWorflowList } from '../../actions/projectWorkflows';
import { accessBasedOnProjectMembers } from '../../helper/common';
import { checkFlowInsertionFromFlows, getAssigneeData, isUserPartOfProject, insertGridItem } from '../TaskSidebar/sidebar.helper';

/**
 * renders remove or delete icon according to condition
 */
export const discardCancel = (isNewItem) =>
  isNewItem ? <span title={tooltip.DISCARD} >
    {icon.CLEAR}
  </span> : <span title={tooltip.CANCEL} >
    {icon.CLEAR}
  </span>

/**
 * renders add or update tooltip according to condition
 */
export const updateCancel = (isNewItem) =>
  isNewItem ? <span title={tooltip.ADD} >
    {icon.DONE}
  </span> : <span title={tooltip.UPDATE} >
    {icon.DONE}
  </span>

/**
 * It handles the toggle change for the task elements and dispatches the respective api call
 * It sets the the respective task element in case toggle is turned on
 * @param {Boolean} item
 * @param {Function} handleSwitchData
 * @param {Object} workflow
 * @param {Function} handleSwitch
 * @author Muskan Thakur
 */
export const handleTaskElementSwitchChange = async (item, handleSwitchData, workflow, handleSwitch) => {
  handleSwitch(item.value)
  if (workflow?.Id) {
    let payload = { workflowId: workflow?.Id };
    payload[item.payloadKey] = !item.checked;
    await store.dispatch(editWorkflow(payload))
    const res = !item.checked && item.api && await Promise.all(item.api.map((api) => store.dispatch(api({ workflowId: workflow?.Id }))));
    res && handleSwitchData(item.value, res)
  }
}

export const getChips = (chipId, details) => {
  let Ids = splitString(chipId, ",")
  let chips = []
  Ids?.map(Id => {
    let chip = details?.find(chip => chip.value == Id);
    chip && chips.push(chip)
  })
  return chips;
}

/**
 * @returns icons for dropdown
 */
export const itemRender = (li, itemProps) => {
  const itemChildren = (<span key={itemProps?.index}>{icon[itemProps.dataItem.icon]} </span>);
  return React.cloneElement(li, li.props, itemChildren);
}

/**
 * @returns icon for dropdown
 */
export const valueRender = (element, value) => {
  if (!value) {
    return element;
  }
  const children = [<span key={value}>{icon[value.icon]} </span>];
  return React.cloneElement(element, { ...element.props }, children);
};

/**
 * constant for workflow reminder options
 */
export const reminders = [{ id: 2, day: 1, text: "1 day before due date", custom: false }, { id: 1, day: 0, text: "On due date", custom: false }, { id: 3, text: "Custom reminder", custom: false }]

/**
 * gets the grid data
 */
export const getItems = (gridData) => {
  return gridData
};

/**
 * updates grid element
 */
export const updateItem = (item, data) => {
  let index = data.findIndex((record) => record.id === item.id);
  data[index] = item;
  return data;
};

/**
 * deletes grid element
 */
export const deleteItem = (item, data) => {
  let index = data.findIndex((record) => record.id === item.id);
  data.splice(index, 1);
  return data;
};

/**
 * deletes selected item from the grid
 */
export const remove = (dataItem, data, setData) => {
  const newData = [...deleteItem(dataItem, data)];
  setData(newData);
};

/**
 * adds new data item to the grid (at bottom)
 */
export const addElementAtBottom = async (dataItem, data, setData, id, workflowId, requiredFields, taskType) => {
  if (requiredFields) {
    dataItem.inEdit = true;
    const newData = insertGridItem(dataItem, data, id, workflowId);
    setData(newData);
    getNotification((taskType == label.CHILD_TASK ? quote.CHILD_TASK_ADDED : quote.SUBTASK_ADDED), notifyIcon.SUCCESS_ICON)
  }
  else getNotification(quote.ENTER_ALL_FIELDS, notifyIcon.WARNING_ICON);
};


/**
 * adds new data item to the grid for flow elements with BR's check(at bottom)
 * @param {Object} payload
 * @author Himanshu Negi
 */
export const addElementAtBottomForFlowElements = async (payload, projectId, workflowId) => {
  const { newDataItem, existingFlowElements, setFlowElementData, id, entity, requiredFields } = payload;
  if (requiredFields) {
    newDataItem.inEdit = true;
    if (checkFlowInsertionFromFlows(existingFlowElements, existingFlowElements?.length - number.ONE, existingFlowElements?.length, newDataItem, true) ||
      (newDataItem.assignmentProjectId == projectId && newDataItem.workflowAssignmentType == number.FIVE && newDataItem.workflowAssignmentId == workflowId)
    ) {
      getNotification(quote.CAN_NOT_COEXIST, notifyIcon.WARNING_ICON)
    } else {
      const newData = insertGridItem(newDataItem, existingFlowElements, id, entity);
      getNotification(quote.FLOW_ELEMENT_ADDED, notifyIcon.SUCCESS_ICON)
      setFlowElementData(newData);
    }
  }
  else getNotification(quote.ENTER_ALL_FIELDS, notifyIcon.WARNING_ICON);
};

/**
 * updates existing element in grid
 */
export const update = (dataItem, data, setData, requiredFields) => {
  if (requiredFields) {
    dataItem.inEdit = false;
    const newData = updateItem(dataItem, data);
    setData(newData);
  }
  else getNotification(quote.ENTER_ALL_FIELDS, notifyIcon.WARNING_ICON);
};

/**
 * removes new data item from the grid
 */
export const discard = (data, setData) => {
  const newData = [...data];
  newData.splice(0, 1);
  setData(newData);
};

export const discardFlowElement = (data, setData) => {
  const newData = [...data];
  newData.splice(data.length - 1, 1);
  setData(newData);
};

/**
 * removes selected item from the edit mode
 */
export const cancel = (dataItem, setData, data) => {
  const originalItem = getItems(data).find(p => p.id === dataItem.id);
  const newData = data?.map(item => item.id === originalItem.id ? originalItem : item);
  setData(newData);
  return originalItem;
};

/**
 * edits existing element in grid
 */
export const edit = (dataItem, data, setData) => {
  setData(data.map(item => item.id === dataItem.id ? {
    ...item,
    inEdit: true
  } : item));
};

/**
 * updates fields of the respective element on change
 */
export const itemChange = (event, data, setData) => {
  const newData = data.map(item => item.id === event.dataItem.id ? {
    ...item,
    [event.field || '']: event.value
  } : item);
  setData(newData);
};

export const handleGridChange = (value, field, data, setData, dataItem) => {
  setData(data.map(item => item?.id == dataItem?.id ? {
    ...item,
    [field]: value
  } : item))
}

/**
 * on editing a field it updates the respective state and disptaches editWorkflow action
 */
export const handleEditChange = async (setData, eventValue, field, value, workflowId, projectId) => {
  setData(eventValue)
  if (workflowId) {
    let payload = { workflowId: parseInt(workflowId) };
    payload[field] = value;
    await store.dispatch(editWorkflow(payload))
    if (projectId) updateProjectWorflowList(field, field === 'icon' ? eventValue?.icon : value, projectId, workflowId);
  }
}

/**
 * @returns workflow with its respective workflow icon and privacy color.
 * returns users with its colorCode
 */
export const valueRenderAssignId = (element, value, currentTheme) => {
  let colorCode = currentTheme == 'dark' ? 'white' : "#000000"
  if (!value) {
    return element;
  }
  const children = [<div className='float-left' key={value?.value}>
    <span style={{ color: value.privacy == number.ONE ? "#56b2d9" : (value.privacy == number.TWO ? colorCode : "maroon") }}>{icon[value?.icon]}</span>
    <span>{value.label}</span>
  </div>];
  return React.cloneElement(element, { ...element.props }, children);
};

/**
 * @returns workflows with their respective workflow icons and privacy color. 
 * returns users with their email Id and their colorCode
 */
export const itemRenderAssignId = (li, itemProps, currentTheme) => {
  let colorCode = currentTheme == 'dark' ? 'white' : "#000000"
  const itemChildren = (<span key={itemProps?.index} className='custom-assignee-dropdown-item text-truncate d-flex flex-column' style={{
    color: itemProps.dataItem.colorCode ? itemProps.dataItem.colorCode : "#000000",
  }} title={itemProps.dataItem.label}>
    <div>
      {itemProps.dataItem.privacy && (
        <span className='assignee-name text-truncate mr-1' style={{ color: itemProps.dataItem.privacy == number.ONE ? "#56B2D9" : itemProps.dataItem.privacy == number.TWO ? colorCode : "maroon" }}>
          {icon[itemProps.dataItem?.icon]}
        </span>
      )}
      <span className='assignee-name text-truncate' style={{ color: colorCode }}>{itemProps.dataItem.label}</span>
    {itemProps.dataItem.Email && <div className='assignee-email text-truncate'>{itemProps.dataItem.Email}</div>}
    </div>
  </span>);
  return React.cloneElement(li, li.props, itemChildren);
}

/**
 * @returns Dropdown data for set due date (task element)
 */
export const setDueDateData = () => {
  let dueDateData = [...Array(number.FIVE_THOUSAND_ONE).keys()];
  dueDateData[0] = "None";
  return dueDateData
}

/**
 *
 * @param {String} workflowName
 * @param {Number} workflowNum
 * @returns default name for a new workflow
 */
export const getDefaultWorkflowName = (workflowName, workflowNum, limit) => {
  return workflowName?.substring(number.ZERO, (limit - (String(workflowNum).length + number.SIX))) + " Flow-" + workflowNum
}

/**
 *
 * @param {Array} childtaskData
 * @returns grid data for ChildTaskSetting in case of existing child tasks
 */
export const setChildTasks = (childtaskData) => {
  return childtaskData?.map(item => ({
    id: item.Id,
    childtaskName: item.ChildtaskName,
    priority: item.Priority,
    workflowAssignmentId: item.WorkflowAssignmentId,
    dueDays: item.Duedays,
    description: item.Description,
    assignedType: item.TaskAssignmentType,
    assignedId: item.TaskAssignmentId,
    workflowAssignmentType: item.WorkflowAssignmentType
  }))
}

/**
 *
 * @param {Array} workflows
 * @param {Object} project
 * @returns {Array} filtered worklows a/c their respective privacy
 */
export const workflowData = (workflows, project) => {
  const state = store.getState(),
    { id } = state.auth.user
  return project?.Privacy == number.TWO ? workflows.filter((item) => item.WorkflowPrivacy != number.THREE) : (accessBasedOnProjectMembers(project, id) ? workflows : workflows.filter((item) => item.WorkflowPrivacy == number.ONE))
}

/**
 * returns filtered projects in alphabetic order
 * @param {Array} projects
 * @param {Integer} projectId
 * @returns {Array} filtered projects list
 * @author Muskan Thakur
 */
export const filteredProjects = (projects, projectId) => {
  return projects?.filter(({ value }) => value !== projectId).sort((a, b) => a.ProjectName.localeCompare(b.ProjectName))
}

/**
 * checks if the personal project
 * WorkflowPrivacy = 1 => shared workflow
 * WorkflowPrivacy = 2 => closed workflow
 * WorkflowPrivacy = 3 => restricted workflow
 * @param {Object} event
 * @param {Array} workflows
 * @returns {Boolean}
 * @author Himanshu Negi
 */
export const checkWorkflowRules = (event, workflows) => {
  const currentValue = event.target.value.value;
  if (currentValue !== number.ONE) {
    const sharedWorkflows = workflows?.filter((workflow) => workflow.WorkflowPrivacy === number.ONE);
    if (sharedWorkflows?.length === number.ONE) {
      getNotification(quote.WORKFLOW_PRIVACY_RULE, notifyIcon.WARNING_ICON);
      return true
    }
  }
  return false;
}

/**
 * handles opening of project sidebar for generic projects and personal projects
 * @param {Integer} projectId
 */
export const openConditionalProjectSidebar = (projectId) => {
  const state = store.getState();
  const { user } = state.auth;
  projectId != user?.myProjectId ? openProjectSidebar(PROJECT_TYPE.COMPANY_PROJECT, projectId) : openProjectSidebar(PROJECT_TYPE.PERSONAL_PROJECT, user?.myProjectId, number.ONE);
}


/**
 * sets dependency to true if subtask/linked task exists else sets dependency to false
 * @param {Array} subtaskData
 * @param {Array} childtaskData
 * @param {Boolean} setDependency
 * @param {Object} workflow
 * @author Muskan Thakur
 */
export const setChildTaskSubTaskDependency = async (subtaskData, childtaskData, setDependency, workflow) => {
  const subChildTaskLength = subtaskData?.length + childtaskData?.length
  const isDependent = subChildTaskLength === number.ZERO ? false : subChildTaskLength === number.ONE ? true : null;

  if (isDependent != undefined) {
    setDependency(isDependent)
    workflow?.Id && await store.dispatch(editWorkflow({ workflowId: workflow?.Id, isDependent: isDependent }))
  }
}

/**
 * moves the scroll to the bottom on adding the fourth element
 * @param {Object} gridRef
 * @param {Array} data
 * @author Muskan Thakur
 */
export const moveScrollAtBottom = (gridRef, data) => {
  const minScrollElements = number.THREE
  if (gridRef && data.length > minScrollElements) {
    const tbodyElement = gridRef.current?.tableElement.querySelector('tbody')
    if (tbodyElement) {
      const lastRowElement = tbodyElement.lastElementChild;
      lastRowElement.scrollIntoView();
    }
  }
}

/**
 * moves the scroll to the description of the child task element
 * @param {Object} gridRef
 * @author Muskan Thakur
 */
export const moveScrollAtElementDescription = (gridRef) => {
  const tbodyElement = gridRef.current?.tableElement.querySelector('tbody > tr > td.k-command-cell.cursor-pointer > div > div.form-row.childtask-desc > div > div > div.k-editor-content > iframe');
  tbodyElement?.scrollIntoView();
};

/**
 * returns a user if it is a part of the assigned project
 * @param {Int} assignedProjectId
 * @returns {Object} User
 * @author Muskan Thakur
 */
export const isUserInAssignedProject = (assignedProjectId) => {
  const state = store.getState();
  const { id } = state.auth.user;
  const { defaultDetails } = state.tasks;
  const project = defaultDetails?.allProjectsList?.find((project) => project.value == assignedProjectId);
  const assigneeList = project && getAssigneeData(project, defaultDetails);
  return assigneeList?.find((user) => user.value == id)
}

/**
 * handles dd3 and dd4 for new and exiting grid data based on dd1 and dd2
 * @param {Object} selectedProject
 * @param {Object} assignmentType
 * @param {Object} setWorkflowAssignmentType
 * @param {Object} setWorkflowAssignedId
 * @author Muskan Thakur
 */
export const handleWorkflowAssignment = (selectedProject, assignmentType, setWorkflowAssignmentType, setWorkflowAssignedId) => {
  const state = store.getState();
  const { defaultDetails } = state.tasks;
  const project = defaultDetails?.allProjectsList?.find((project) => project.ProjectId == selectedProject?.ProjectId)
  const updatedWorkflowAssignmentType = defaultDetails?.relatedAssignmentTypeList?.find((type) => type.value === (selectedProject?.ProjectId && (isUserPartOfProject(defaultDetails, project) ? number.TWO : number.FIVE)));
  setWorkflowAssignmentType(updatedWorkflowAssignmentType)
  if (assignmentType?.value === number.THREE) {
    const user = isUserInAssignedProject(selectedProject?.ProjectId)
    setWorkflowAssignedId(user ? user : null);
  }
}
