import { useState, createContext } from "react";
import { number } from "../../../config";

export const RecurringTaskContext = createContext(null);

/**
 * wrapper component for recurring task context provider 
 * @author Muskan Thakur
*/
export const RecurringTaskContextProvider = ({ children }) => {
    const [calenderValue, setCalenderValue] = useState(new Date());
    const [recurrencePattern, setRecurrencePattern] = useState(number.ONE);
    const [recur, setRecur] = useState({});
    const [reminder, setReminder] = useState([]);
    const [title, setTitle] = useState(null);
    const [duration, setDuration] = useState({});
    const [showEndByDatepicker, setShowEndByDatepicker] = useState(true);
    const [endBydateValue, setEndBydateValue] = useState(null);
    const [endByCountValue, setEndByCountValue] = useState(number.SEVEN);
    const [skipCountValue, setSkipCountValue] = useState(number.THREE);
    const [recurrenceMode, setRecurrenceMode] = useState(number.ONE);
    const [skipPastDueDates, setSkipPastDueDates] = useState(true);
    const [recurrDisabled, setRecurrDisabled] = useState(false);
    const [edit, setEdit] = useState(false);
    const [project, setProject] = useState();
    const [isSaveDisabled, setIsSaveDisabled] = useState(false);
    const [recurrenceCreator, setRecurrenceCreator] = useState();
    const [patternBasedRecur, setPatternBasedRecur] = useState();
    const [updatedPayload, setUpdatedPayload] = useState();
    const [assignedUser, setAssignedUser] = useState();
    const [skipDays, setSkipDays] = useState([true]);
    const [assignedId, setAssignedId] = useState();
    const [assigneeLists, setAssigneeLists] = useState("");
    const [projectUserData, setProjectUserData] = useState();
    const [workflows, setWorkflows] = useState([]);
    const [taskAssignmentType, setTaskAssignmentType] = useState();
    const [reminderValue, setReminderValue] = useState();
    const [skipPastDate, setSkipPastDate] = useState(false);
    const [skipSaturday, setSkipSaturday] = useState(false);
    const [skipSunday, setSkipSunday] = useState(false);
    const [frequencyInterval, setFrequencyInterval] = useState(number.ONE);
    const [frequencyIntervalType, setFrequencyIntervalType] = useState(null);
    const [weeklyCustomRecurDay, setWeeklyCustomRecurDay] = useState([]); 
    const [nextRecurrenceDate, setNextRecurrenceDate] = useState(null);
    const [monthlyCustomRecurDates, setMonthlyCustomRecurDates] = useState([]); 

  const value = {
    calenderValue, 
    setCalenderValue,
    recurrencePattern, 
    setRecurrencePattern,
    recur, 
    setRecur,
    reminder, 
    setReminder,
    title, 
    setTitle,
    duration, 
    setDuration,
    showEndByDatepicker, 
    setShowEndByDatepicker,
    endBydateValue, 
    setEndBydateValue,
    endByCountValue, 
    setEndByCountValue,
    skipCountValue, 
    setSkipCountValue,
    recurrenceMode, 
    setRecurrenceMode,
    skipPastDueDates, 
    setSkipPastDueDates,
    recurrDisabled, 
    setRecurrDisabled,
    edit, 
    setEdit,
    project, 
    setProject,
    isSaveDisabled, 
    setIsSaveDisabled,
    recurrenceCreator, 
    setRecurrenceCreator,
    patternBasedRecur, 
    setPatternBasedRecur,
    updatedPayload, 
    setUpdatedPayload,
    assignedUser, 
    setAssignedUser,
    skipDays, 
    setSkipDays,
    assignedId, 
    setAssignedId,
    assigneeLists, 
    setAssigneeLists,
    projectUserData, 
    setProjectUserData,
    workflows, 
    setWorkflows,
    taskAssignmentType, 
    setTaskAssignmentType,
    reminderValue, 
    setReminderValue,
    skipPastDate, 
    setSkipPastDate,
    skipSaturday, 
    setSkipSaturday,
    frequencyInterval, 
    setFrequencyInterval,
    frequencyIntervalType, 
    setFrequencyIntervalType,
    weeklyCustomRecurDay, 
    setWeeklyCustomRecurDay,
    nextRecurrenceDate, 
    setNextRecurrenceDate,
    monthlyCustomRecurDates, 
    setMonthlyCustomRecurDates,
    skipSunday, 
    setSkipSunday
  }

  return (
    <RecurringTaskContext.Provider value={value}>{children}</RecurringTaskContext.Provider>
  )

}