import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory, useLocation } from 'react-router-dom'
import { updateCurrUser } from '../../../../actions/auth'
import { setNavigationTasks } from '../../../../actions/navigationTasks'
import { resetPlanMyday, setPlanMyDay } from '../../../../actions/planMyDay'
import { resetSidebarReducer } from '../../../../actions/taskSidebar'
import { getPlannedTask, openSidebar } from '../../../../components/Tasks/tasks.service'
import { icon, label, notifyIcon, number, quote, route } from '../../../../config'
import { getNotification } from '../../../../utils/common'

/**
 * Plan my day icons component
 * Parent component => Header component
 * @returns JSX
 * @author Himanshu Negi
 */
const PlanMyDay = () => {
  const location = useLocation();
  const history = useHistory();
  const dispatch = useDispatch();

  const { user } = useSelector((state) => state.auth);
  const { isPlanMyDayActive, planMyDayTasks } = useSelector((state) => state.planMyDay);

  /**
   * reset state for plan my day
   * @author Himanshu Negi
   */
  useEffect(() => {
    if (location?.pathname !== route.PRIVATE_ROUTE.TASKS.PATH && isPlanMyDayActive) {
      dispatch(resetPlanMyday())
      dispatch(resetSidebarReducer())
    }
  }, [location?.pathname, isPlanMyDayActive])



  /**
   * Header icon handler for plan my day  
   * @returns {Void}
   * @author Himanshu Negi
   */
  const planMyDayHandler = async () => {
    dispatch(resetSidebarReducer())
    const allPlanTasks = await getPlannedTask(user.id);
    if (allPlanTasks?.every(task => task.IsPlanned === number.ONE)) return getNotification(quote.ALL_TASK_PLANNED, notifyIcon.SUCCESS_ICON);
    let planTasks = allPlanTasks?.filter((task) => !task?.IsPlanned);
    if (location?.pathname !== route.PRIVATE_ROUTE.TASKS.PATH) history.push(route.PRIVATE_ROUTE.TASKS.PATH);
    dispatch(setPlanMyDay({ planTasks: [...planTasks], allPlanTasks: [...allPlanTasks] }))
    const updatedUser = { IsPlanMyDayClicked: number.ONE }
    dispatch(updateCurrUser(updatedUser))
    dispatch(setNavigationTasks([...planTasks]))
    const lastPlannedTaskIndex = planTasks?.findLastIndex((task) => task?.IsPlanned);
    openSidebar({ id: planTasks[lastPlannedTaskIndex + number.ONE]?.Id, userId: user?.id }, true);
  }
  return (
    <>
        <button id='plan-my-day-header-icon' className='btn header-item header-icon header-hover-btn ml-2' onClick={planMyDayHandler} title={label.PLAN_MY_DAY}>{icon.PLAN_DO_LATER}</button>
        {!!(!user?.IsPlanMyDayClicked) && <span className='red-dot rounded-circle position-absolute'></span>}
    </>
  )
}

export default PlanMyDay;