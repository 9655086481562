import React, { useEffect, useRef, useState } from "react";
import { Button } from "@progress/kendo-react-buttons";
import { exportToSvg, Excalidraw, useHandleLibrary } from "@excalidraw/excalidraw";
import { uploadEditorCanvas, uploadEditorS3IFrame } from "../../shared/services/common.service";
import { generateUniqueId } from "./GroupedMediaTools/groupedMedia-utils";
import "../Draw.scss";
import { useSelector } from "react-redux";
import { useEditorContext } from "../editor.Context";
import { button, number } from "../../config";
import  ReactDOM from "react-dom";

/**
 * Creates a resolvable promise which exposes resolve and reject functions.
 * @returns {Promise} A promise object with resolve and reject methods.
 * @author Shivam Mishra
 */
export const resolvablePromise = () => {
    let resolve, reject;
    const promise = new Promise((_resolve, _reject) => {
        resolve = _resolve;
        reject = _reject;
    });
    promise.resolve = resolve;
    promise.reject = reject;
    return promise;
};

/**
 * ExcalidrawDialog component for handling drawing canvas, exporting to SVG, and inserting into the editor.
 * @param {Object} props - Component properties.
 * @param {Function} props.onClick - Function to handle dialog close.
 * @param {Object} props.view - Editor view state.
 * @param {Function} props.updateNode - Function to update the node.
 * @param {Object} props.iframeProp - Properties of the iframe to update.
 * @param {Function} props.updateIframe - Function to update the iframe with exported SVG.
 * @returns {JSX.Element} The rendered dialog component for Excalidraw.
 * @author Shivam Mishra
 */
const ExcalidrawDialog = ({ onClick, view, updateNode, iframeProp, updateIframe }) => {
    const appRef = useRef(null);
    const { user } = useSelector((state) => state.auth);
    const editorState = useEditorContext();
    const { setEditFrameId, setVisibleDialog } = editorState;
    const [exportWithDarkMode] = useState(false);
    const [exportEmbedScene] = useState(false);
    const [viewModeEnabled] = useState(false);
    const [zenModeEnabled] = useState(false);
    const [gridModeEnabled] = useState(false);
    const [excalidrawAPI, setExcalidrawAPI] = useState(null);

    const initialStatePromiseRef = useRef({ promise: null });
    if (!initialStatePromiseRef.current.promise) {
        initialStatePromiseRef.current.promise = resolvablePromise();
    }

    useHandleLibrary({ excalidrawAPI });

    useEffect(() => {
        if (!excalidrawAPI) return;

        const loadInitialData = async () => {
            const savedElements = await uploadEditorCanvas({ canvasString: "", canvasId: iframeProp.id });
            let parsedElements = [];

            if (savedElements) {
                try {
                    parsedElements = JSON.parse(savedElements.CanvasString);
                } catch (error) {
                    console.error( error);
                }
            }
            if (parsedElements.length === number.ZERO) return;

            const initialDataX = { elements: parsedElements.length ? parsedElements : [] };
            initialStatePromiseRef.current.promise.resolve(initialDataX);
        };

        updateNode ? loadInitialData() : initialStatePromiseRef.current.promise.resolve({ elements: [] });
    }, [excalidrawAPI]);

    /**
 * Inserts the exported image as an iframe node into the editor.
 * @param {string} result - The exported image URL.
 * @param {string} id - Unique identifier for the iframe node.
 */
    const insertImage = (result, id) => {
        const schema = view.state.schema;
        const nodeType = schema.nodes.iframe;

        const node = nodeType.createAndFill({
            src: result,
            style: `width: 40vw; height: 30vh;`,
            id: id
        });

        if (node) {
            let transaction = view.state.tr;
            transaction.insert(view.state.selection.head, node);
            const paragraphNode1 = schema.nodes.paragraph.create();
            transaction.insert(view.state.selection.head + number.ONE, paragraphNode1);
            view.dispatch(transaction);
            view.focus();
        }
        onClose();
    };

    /**
 * Closes the dialog and performs necessary cleanup operations.
 */
    const onClose = () => {
        onClick();
        setVisibleDialog(false);
        setEditFrameId(null);
    };

    /**
 * Handles exporting the current drawing as an SVG and uploading it to the server.
 * It also inserts the exported SVG into the editor as an iframe.
 * @async
 */

    const handleSvgExport = async () => {
        if (!excalidrawAPI) {
            return;
        }
        const svg = await exportToSvg({
            elements: excalidrawAPI?.getSceneElements(),
            appState: {
                exportEmbedScene,
                exportWithDarkMode,
                width: number.THREE_HUNDRED,
                height: number.HUNDRED,
                viewBackgroundColor: "#ADD8E6",
            },
            files: excalidrawAPI?.getFiles(),
            exportPadding: number.ZERO
        });

        const blob = new Blob([svg.outerHTML], {
            type: "image/svg+xml",
        });
        const formData = new FormData();
        formData.append("files", blob);
        const data = await uploadEditorS3IFrame(formData);
        const elementsString = JSON.stringify(excalidrawAPI?.getSceneElements());
        const id = generateUniqueId(user.id, "canvas", "draw");
        uploadEditorCanvas({ canvasString: elementsString, canvasId: updateNode ? iframeProp.id : id });
        if (updateNode) {
            updateIframe({ src: data.Location, width: number.FORTY, height: number.FORTY, id: iframeProp.id });
            onClose();
        } else {
            insertImage(data.Location, id);
        }
    };

    return ReactDOM.createPortal(
        <div className="custom-dialog-overlay" id="excalidraw-dialog">
            <div className="custom-dialog" onClick={(e) => e.stopPropagation()}>
                <div className="dialog-body">
                    <div className="excali-app" ref={appRef}>
                        <div className="excalidraw-wrapper">
                            <Excalidraw
                                ref={excalidrawAPI}
                                excalidrawAPI={(api) => setExcalidrawAPI(api)}
                                initialData={initialStatePromiseRef.current.promise}
                                viewModeEnabled={viewModeEnabled}
                                zenModeEnabled={zenModeEnabled}
                                gridModeEnabled={gridModeEnabled}
                                name="Custom name of drawing"
                                UIOptions={{
                                    canvasActions: { loadScene: true },
                                }}
                            />
                        </div>
                    </div>
                </div>
                <div className="dialog-footer">
                    <Button onClick={onClose}>
                        {button.CLOSE}
                    </Button>
                    <Button className='btn btn-primary'onClick={handleSvgExport}>
                        {button.SAVE}
                    </Button>
                </div>
            </div>
        </div>
        ,
        document.body
    );
};

export default ExcalidrawDialog;