import * as React from 'react';
import { useState, useEffect } from 'react';
import { Dialog, DialogActionsBar } from "@progress/kendo-react-dialogs";
import { Button } from '@progress/kendo-react-buttons';
import { EditorUtils } from '@progress/kendo-react-editor';
import { generateUniqueId, isValidInputUrl } from './groupedMedia-utils';
import { useSelector } from 'react-redux';
import { useEditorContext } from '../../editor.Context';
import { button, icon, label, number } from '../../../config';

/**
 * UrlDialog Component
 *
 * @param {Object} props - Component properties.
 * @param {Function} setDialog - Function to set the visibility of the dialog.
 * @param {Function} updateNode - Function to update the node in the editor.
 * @param {Object} iframeProp - Properties of the iframe being edited.
 * @param {Function} updateIframe - Function to update the iframe in the editor.
 * @returns {JSX.Element} The UrlDialog component.
 * @author Shivam
 */
const UrlDialog = ({ props, setDialog, updateNode, iframeProp, updateIframe ,removeIframe}) => {
  const { view } = props;
  const { user } = useSelector((state) => state.auth);
  const editorState = useEditorContext();
  const { setEditFrameId, setVisibleDialog } = editorState;
  const [url, setUrl] = useState('https://drutas.com/');
  const [width, setWidth] = useState(number.SIXTY);
  const [height, setHeight] = useState(number.SIXTY);
  const [isValidUrl, setIsValidUrl] = useState(true);
  const [isValidSize, setIsValidSize] = useState(true);

  // Extract existing iframe properties if updating
  useEffect(() => {
    if (updateNode && iframeProp) {
      const extractValue = (value) => value && parseInt(value?.replace(/\D/g, ''), number.TEN);
      setHeight(extractValue(iframeProp?.height));
      setWidth(extractValue(iframeProp?.width));
      setUrl(iframeProp.src);
    }
  }, [updateNode]);

  // Validate URL whenever it changes
  useEffect(() => {
    validateUrl(url);
  }, [url]);

  // Validate size whenever width or height changes
  useEffect(() => {
    const validateSize = (width, height) => {
      setIsValidSize(width <= 100 && height <= 100);
    };
    validateSize(width, height);
  }, [width, height]);

  /**
   * Validates the URL and sets the validity state.
   * 
   * @param {string} value - The URL to validate.
   */
  const validateUrl = (value) => {
    setIsValidUrl(isValidInputUrl(value));
  };

  /**
   * Closes the dialog and resets relevant state.
   */
  const onClose = () => {
    setVisibleDialog(false);
    setEditFrameId(null);
    setDialog && setDialog(false);
  };

  /**
   * Handles the cancel action, closing the dialog.
   */
  const handleCancel = () => {
    onClose();
  };

  const setDefault = () => {
    setHeight(number.SIXTY) ; 
    setWidth(number.SIXTY);
    setUrl('https://drutas.com/');
  }

  /**
   * Inserts or updates an iframe node in the editor.
   */
  const insertIframe = () => {
    if (!isValidSize) return;
  
    const schema = view.state.schema;
    const nodeType = schema.nodes.iframe;
    const id = generateUniqueId(user.id, "url");
    const updatedUrl = url?.startsWith("http://") || url?.startsWith("https://") ? url : `https://${url}`;
  
    if (updateNode) {
      const location = updatedUrl || iframeProp.src;
     
      updateIframe({ src: location, width: width, height: height, id: iframeProp.id });
      onClose();
      return;
    }

  
    const node = nodeType.createAndFill({
      src: updatedUrl,
      style: `width: ${width}vw; height: ${height}vh;`,
      id: id
    });
  
    if (node) {
    let transaction = view.state.tr;
    transaction.insert(view.state.selection.head, node);
    const paragraphNode1 = schema.nodes.paragraph.create();
    transaction.insert(view.state.selection.head + 1, paragraphNode1);
    view.dispatch(transaction);
    view.focus();
    onClose();
    return;
    }
  };
  
  /**
  * handle Height Change.
  */
  const handleHeightChange = (event) => {
    const newHeight = Number(event.target.value);
    setHeight(newHeight);
  };
  
  /**
  * handle Width Change.
  */
  const handleWidthChange = (event) => {
    const newWidth = Number(event.target.value);
    setWidth(newWidth);
  };

  return (
    <Dialog title={label.EDIT_EXTERNAL_URL} onClose={onClose} className='dialog-index'>
      <div className='form-group'>
        <label>{label.URL}</label>
        <div className='d-flex align-items-center'>
          <input
            className='form-control'
            value={url}
            onChange={(e) => setUrl(e.target.value)}
            id="url-dialog-input"
          />
          <span className='ml-2 cursor-pointer' onClick={(e) => setUrl("")}>{icon.CANCEL_ICON}</span>
        </div>
        {!isValidUrl && <div className='mt-2 file-size-error'>{label.VALID_URL}</div>}
      </div>
      <div className='form-row'>
        <div className='form-group col-md-6'>
          <label>{label.WIDTH_}</label>
          <div>
            <div className='d-flex align-items-center'>
              <input
                className='form-control w-50'
                value={width}
                onChange={handleWidthChange}
                id="url-dialog-width-input"
              />
              <span className='small ml-2'>(%)</span>
            </div>
            {width > number.HUNDRED && <div className='mt-2 file-size-error'>{label.VALID_WIDTH}</div>}
          </div>
        </div>
        <div className='form-group col-md-6'>
          <label>{label.HEIGHT_}</label>
          <div>
            <div className='d-flex align-items-center'>
              <input
                className='form-control w-50'
                value={height}
                onChange={handleHeightChange}
                 id="url-dialog-height-input"
              />
              <span className='small ml-2'>(%)</span>
            </div>
            {height > number.HUNDRED && <div className='mt-2 file-size-error' >{label.VALID_HEIGHT}</div>}
          </div>
        </div>
      </div>
      <DialogActionsBar>
        <div className='d-flex justify-content-between align-items-center'>
            <span className='link-text small cursor-pointer' onClick = {setDefault} id = "url-dialog-default-btn">{button.DEFAULT}</span>
            <div>
              {updateNode && <Button className='btn btn-secondary mr-2' type="button" onClick={()=>removeIframe(iframeProp.id)} id = "url-dialog-cancel-btn">{icon.TRASH}</Button> }
              <Button className='btn btn-secondary mr-2' type="button" onClick={handleCancel} id = "url-dialog-cancel-btn">{button.CANCEL}</Button>
              <Button className='btn-width btn btn-primary' type="button" id = "url-dialog-save-btn" onClick={insertIframe} disabled={!isValidUrl || !isValidSize}>{button.SAVE}</Button>
            </div>
        </div>
      </DialogActionsBar>
    </Dialog>
  );
};

export default UrlDialog;
