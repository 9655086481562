import { useDispatch, useSelector } from "react-redux";
import { setKanbanTasks } from "../../../actions/kanbanUpdates";
import { getGroupedTasks } from "../kanban.helper";

export const useKanbanUpdates = () => {
  const dispatch = useDispatch();
  const { kanbanTasks, groupBy } = useSelector((state) => state.kanbanUpdates);
  const { defaultDetails } = useSelector((state) => state.tasks);

  const updateKanbanName = (taskId, name) => {
    const newKanbanTasks = kanbanTasks?.map((task) => (task?.id === taskId ? { ...task, name } : task));
    dispatch(setKanbanTasks(newKanbanTasks));
  };

  const updateKanbanPriority = (taskId, priorityId) => {
    let newKanbanTasks = kanbanTasks?.map((task) => (task?.id === taskId ? { ...task, priorityId } : task));
    newKanbanTasks = getGroupedTasks(newKanbanTasks, groupBy);
    dispatch(setKanbanTasks(newKanbanTasks));
  };

  const updateKanbanDueDate = (taskId, dueDate) => {
    const newKanbanTasks = kanbanTasks?.map((task) => (task?.id === taskId ? { ...task, dueDate } : task));
    dispatch(setKanbanTasks(newKanbanTasks));
  };

  const updateKanbanAssignee = (taskId, assignee) => {
    const assigneeDetails = defaultDetails?.assigneeList?.find((assigneeDetail) => Number(assigneeDetail?.value) === Number(assignee));
    let newKanbanTasks = kanbanTasks?.map((task) => (task?.id === taskId ? { ...task, assignee, assigneeName: assigneeDetails?.label } : task));
    newKanbanTasks = getGroupedTasks(newKanbanTasks, groupBy);
    dispatch(setKanbanTasks(newKanbanTasks));
  };

  const updateKanbanStage = (taskId, stageId, assignee) => {
    const stageDetails = defaultDetails?.stagesConfig?.find((stage) => Number(stage?.value) === Number(stageId));
    const assigneeDetails = defaultDetails?.assigneeList?.find((assigneeDetail) => Number(assigneeDetail?.value) === Number(assignee));
    let newKanbanTasks = kanbanTasks?.map((task) =>
      task?.id === taskId
        ? {
            ...task,
            status: stageDetails?.label,
            value: stageId,
            assignee: assigneeDetails ? assignee : task?.assignee,
            assigneeName: assigneeDetails?.label ? assigneeDetails?.label : task?.assigneeName,
          }
        : task
    );
    newKanbanTasks = getGroupedTasks(newKanbanTasks, groupBy);
    dispatch(setKanbanTasks(newKanbanTasks));
  };

  return { updateKanbanName, updateKanbanPriority, updateKanbanDueDate, updateKanbanAssignee, updateKanbanStage };
};
