import * as ACTION_TYPES from "../actions/action-types";
import { TASK_KEY_MAP } from "../components/TaskSidebar/sidebar.constants";
import { number } from "../config";

const initialState = {
  showTaskSidebar: false,
  isNewTask: false,
  expanded: [],
  scrollSection: '',
  task: {},
  isNewTaskSaved: false,
  newTaskData: {
    id: null,
    assignedId: null,
    assignedType: number.TWO,
    dueDate: null,
    isFollowed: true,
    isReturnable: true,
    name: "",
    parentTaskId: null,
    priority: null,
    project: null,
    stageId: null,
    tags: "",
    description: "",
    parentLinkTaskCount: number.ZERO,
    childOrSubtaskCount: number.ZERO,
    companyId: null,
    followers: '',
    taskUrl: '',
    attachmentCount: number.ZERO,
    tempTaskDesc: '',
    relatedAssignedType: number.FIVE,
    relatedAssignedId: null,
    isDescChanged: false,
    mode: 2,
    modeCreator: null,
  },
  stages: [],
  flowElements: [],
  showAttachments: true,
  showDescription: true,
  showSimplifiedDetail: true,
  showLinkedTasksSubTasks: true,
  showComments: true,
  descriptionPopupSetting: false,
  showLoader: false,
  allAttachments: [],
  taskAttachments: [],
  currentFocus: '',
  focusedElement: '',
  isExistingTaskDescChanged:false,
  taskDescChangeLogSent: false,
  cloneTaskId: null,
  cloneOrNewTaskAttachments: [],// object array of cloned or new task attachments 
  taskUrl: '',
  fileContent: [],
  cloned: [],
  loaderComponent: {
    dueDate: false,
    priority: false,
    stageId: false,
    description: false,
    tags: false,
    assignedId: false,
    name: false,
  },
  showDescPopup: { status: false, type: "" },
  isApproval: false,
  approvalLogs: [],
  isDependent: false,
  showTaskOwnerShip: false,
  showTransferOwnershipPopup: false,
  currentView: 0,
  sharedWorkflowDetail: {
    createTask: false,
    sharedProjectId: null,
    sharedFlowId: null
  },
  isSidebarLoading: false,
  cloneRecurrenceEnabled: false
}

export const updateloaderComponent = (data, payload) => {
  const updatedLoader = { ...data, [payload]: !data[payload] }
  return updatedLoader
}


/**
* setting states according to the type of actions
* @param {*} state
* @param {*} action
* @returns {state}
*/

const taskSidebar = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case ACTION_TYPES.TOGGLE_TASK_SIDEBAR:
      return {
        ...state,
        task: {},
        flowElements: state.cloneRecurrenceEnabled ? state.flowElements : (payload.showTaskSidebar ? state.flowElements : []),
        showTaskSidebar: payload.showTaskSidebar,
        isNewTask: payload.isNewTask ? payload.isNewTask : false,
      }
    case ACTION_TYPES.SET_PINNED_TASKSIDEBAR:
      return {
        ...state,
        task: { ...state.task, pinned: action.payload }
      }
      
      break;
    case ACTION_TYPES.SET_NUDGE:
      return {
        ...state,
        task: { ...state.task, nudge: action.payload }
      }
      break;
    case ACTION_TYPES.SET_EXPANDED:
      return {
        ...state,
        expanded: payload
      }
    case ACTION_TYPES.SET_TASK_DETAIL:
      return {
        ...state,
        task: payload,
      }
    case ACTION_TYPES.SET_NEW_TASK_DATA:
      return {
        ...state,
        newTaskData: { ...state.newTaskData, [payload.key]: payload.value }
      }
    case ACTION_TYPES.SET_TASK_ON_BLUR:
      return {
        ...state,
        task: { ...state.task, [payload.key]: payload.value }
      }
    case ACTION_TYPES.SET_IS_TASK_SAVED:
      return {
        ...state,
        isNewTaskSaved: payload
      }
    case ACTION_TYPES.ALL_ATTACHMENT:
      return {
        ...state,
        allAttachments: payload
      }
    case ACTION_TYPES.SET_TASK_ATTACHMENTS:
      return {
        ...state,
        taskAttachments: payload
      }
    case ACTION_TYPES.CLONE_TASK:
      return {
        ...state,
        cloneTaskId: payload
      }
    case ACTION_TYPES.RESET_SIDEBAR:
      return initialState;
    case ACTION_TYPES.TOGGLE_SIDEBAR_LOADER:
      return {
        ...state,
        showLoader: payload.showLoader
      }
    case ACTION_TYPES.GET_FOCUSED_SECTION:
      return { ...state, currentFocus: payload }
    case ACTION_TYPES.SET_FOCUSED_ELEMENT:
      return {
        ...state,
        focusedElement: payload,
      };
    case ACTION_TYPES.CLONE_OR_NEW_TASK_ATTACHMENT_DATA:
      return {
        ...state,
        cloneOrNewTaskAttachments: payload
      }
    case ACTION_TYPES.SET_STAGES:
      return {
        ...state,
        stages: payload
      }
    case ACTION_TYPES.SET_ASSIGNMENT_DEFAULTS:
      return {
        ...state,
        assignmentDefault: payload
      }
    case ACTION_TYPES.SET_FLOW_ELEMENTS:
      return {
        ...state,
        flowElements: payload
      }
    case ACTION_TYPES.SET_SHOW_ATTACHMENT:
      return {
        ...state,
        showAttachments: payload
      }
    case ACTION_TYPES.SET_SHOW_DESCRIPTION:
      return {
        ...state,
        showDescription: payload
      }
    case ACTION_TYPES.SET_SHOW_SIMPLIFIED_DETAIL:
      return {
        ...state,
        showSimplifiedDetail: payload
      }
    case ACTION_TYPES.SET_SHOW_LINKED_SUB_TASK:
      return {
        ...state,
        showLinkedTasksSubTasks: payload
      }
    case ACTION_TYPES.SET_SHOW_COMMENTS:
      return {
        ...state,
        showComments: payload
      }
    case ACTION_TYPES.ATTACHMENT_FILE_CONTENT:
      return {
        ...state,
        fileContent: payload
      }
    case ACTION_TYPES.CLONED:
      return {
        ...state,
        cloned: payload
      }
    case ACTION_TYPES.UPDATE_FOLLOWER_IDS:
      const taskKey = TASK_KEY_MAP['followers'];
      return {
        ...state,
        task: { ...state.task, [taskKey?.value]: payload }
      }
    case ACTION_TYPES.UPDATE_FOLLOWER_NEW_TASK:
      return {
        ...state,
        newTaskData: { ...state.newTaskData, ['followers']: payload }
      }
    case ACTION_TYPES.UPDATE_TASK_URL:
      return {
        ...state,
        taskUrl: payload
      }
    case ACTION_TYPES.SET_LINK_TASK_COUNT:
      return {
        ...state,
        task: {
          ...state.task, parentLinkTaskCount: action.payload.parentLinkTaskCount,
          inCompleteChildtaskCount: action.payload.inCompleteChildtaskCount
        }
      }
    case ACTION_TYPES.SET_LOADER:
      let loaderState = state.loaderComponent
      return {
        ...state,
        loaderComponent: updateloaderComponent(loaderState, action.payload)
      }
    case ACTION_TYPES.TOGGLE_DESC_POPUP:
      return {
        ...state,
        showDescPopup: payload
      }
    case ACTION_TYPES.TOGGLE_APPROVAL:
      return {
        ...state,
        isApproval: payload
      }
    case ACTION_TYPES.SET_APPROVAL_LOGS:
      return {
        ...state,
        approvalLogs: payload
      }
    case ACTION_TYPES.SET_TEMPORY_TASK_DESCRIPTION:
      return {
        ...state,
        tempTaskDesc: payload
      }
    case ACTION_TYPES.SET_INCOMPLETE_SUBTASK_COUNT:
      return {
        ...state,
        task: {
          ...state.task, inCompleteSubtaskCount: action.payload
        }
      }
    case ACTION_TYPES.SET_DEPENDENCY_TOGGLE:
      return {
        ...state,
        isDependent: payload
      }
    case ACTION_TYPES.SET_SCROLL_SECTION:
      return {
        ...state,
        scrollSection: payload
      }
    case ACTION_TYPES.SHOW_TASK_OWNERSHIP:
      return {
        ...state,
        showTaskOwnerShip: payload
      }
      case ACTION_TYPES.SHOW_TRANSFER_OWNERSHIP:
      return {
        ...state,
        showTransferOwnershipPopup: payload
      }

    case ACTION_TYPES.SET_CURRENT_VIEW:
      return {
        ...state,
        currentView: payload
      }

    case ACTION_TYPES.SET_NEW_COMMENT_COUNT:
      return {
        ...state,
        task: { ...state.task, newCommentCount: payload }
      }
    case ACTION_TYPES.CREATE_TASK_VIA_SHARED_FLOW:
      return {
        ...state,
        sharedWorkflowDetail: { ...state.sharedWorkflowDetail, createTask: payload.createTask, sharedProjectId: payload.sharedProjectId, sharedFlowId: payload.sharedFlowId }
      }
    case ACTION_TYPES.SET_SIDEBAR_LOADING_STATE:
      return {
        ...state,
        isSidebarLoading: payload
      }
    case ACTION_TYPES.UPDATE_DESC_MODE:
      return {
        ...state,
        task: { ...state.task, mode: payload.mode, modeCreator: payload.userId }
      }
      case ACTION_TYPES.UPDATE_CREATOR:
      return {
        ...state,
        task: { ...state.task, CreatedBy : payload }
      }
      case ACTION_TYPES.CLONE_RECURRENCE_ENABLED:
        return {
          ...state,
          cloneRecurrenceEnabled: true
        }
        case ACTION_TYPES.EXISTING_TASK_DESC_CHANGED:
          return {
            ...state,
            isExistingTaskDescChanged: true
          }
        case ACTION_TYPES.TASK_DESC_LOG_SENT:
            return {
              ...state,
              taskDescChangeLogSent: true
          }  
    case ACTION_TYPES.CLONE_RECURRENCE_ENABLED:
      return {
        ...state,
        cloneRecurrenceEnabled: true
      }

      case ACTION_TYPES.SET_DESCRIPTION_POPUP_SETTING:
        return {
          ...state,
          descriptionPopupSetting: payload
        }
    default:
      return state;
  }
}

export default taskSidebar;