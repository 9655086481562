import * as ACTION_TYPES from './action-types';

export const setShowTaskSidebar = (data) => {
  return {
    type: ACTION_TYPES.TOGGLE_TASK_SIDEBAR,
    payload: data
  }
}

export const setExpanded = (data) => {
  return {
    type: ACTION_TYPES.SET_EXPANDED,
    payload: data,
  }
}

/**
 * Action handler for saving pinned of a task in reducer on pinning a task from task sidebar
 * @param {Boolean} data 
 * @returns 
 */
export const setPinTask = (data) => {
  return {
    type: ACTION_TYPES.SET_PINNED_TASKSIDEBAR,
    payload: data,
  }
}

export const setTask = (data) => {
  return {
    type: ACTION_TYPES.SET_TASK_DETAIL,
    payload: data
  }
}

export const setNewTaskData = (data) => {
  return {
    type: ACTION_TYPES.SET_NEW_TASK_DATA,
    payload: data,
  }
}

export const setTaskOnBlur = (data) => {
  return {
    type: ACTION_TYPES.SET_TASK_ON_BLUR,
    payload: data
  }
}

export const setIsTaskSaved = (value) => {
  return {
    type: ACTION_TYPES.SET_IS_TASK_SAVED,
    payload: value
  }
}

export const resetSidebarReducer = () => {
  return {
    type: ACTION_TYPES.RESET_SIDEBAR
  }
}

export const toggleSidebarLoader = (show) => {
  return {
    type: ACTION_TYPES.TOGGLE_SIDEBAR_LOADER,
    payload: show
  }
}

export const getFocusedSection = (data) => {
  return {
    type: ACTION_TYPES.GET_FOCUSED_SECTION,
    payload: data
  }
}

export const setFocusedElement = (elementId) => ({
  type: ACTION_TYPES.SET_FOCUSED_ELEMENT,
  payload: elementId,
});

export const cloneTask = (payload) => {
  return {
    type: ACTION_TYPES.CLONE_TASK,
    payload: payload
  }
}

export const allAttachmentData = (payload) => {
  return {
    type: ACTION_TYPES.ALL_ATTACHMENT,
    payload: payload
  }
}
export const cloneAttachment = (payload) => {
  return {
    type: ACTION_TYPES.CLONE_ATTACHMENT,
    payload: payload
  }
}

export const cloneOrNewTaskAttachmentData = (payload) => {
  return {
    type: ACTION_TYPES.CLONE_OR_NEW_TASK_ATTACHMENT_DATA,
    payload: payload
  }
}


export const cloned = (payload) => {
  return {
    type: ACTION_TYPES.CLONED,
    payload: payload
  }
}


export const updateFollowerIds = (data) => {
  return {
    type: ACTION_TYPES.UPDATE_FOLLOWER_IDS,
    payload: data,
  };
};

export const updateFollowerForNewTask = (data) => {
  return {
    type: ACTION_TYPES.UPDATE_FOLLOWER_NEW_TASK,
    payload: data,
  };
};

export const storeTaskUrl = (data) => {
  return {
    type: ACTION_TYPES.UPDATE_TASK_URL,
    payload: data,
  };
};

export const storeLinkTaskCount = (data) => {
  return {
    type: ACTION_TYPES.SET_LINK_TASK_COUNT,
    payload: data,
  };
};



export const setLoader = (payload) => {
  return {
    type: ACTION_TYPES.SET_LOADER,
    payload: payload
  }
}

export const toggleDescPopup = (data) => {
  return {
    type: ACTION_TYPES.TOGGLE_DESC_POPUP,
    payload: data
  }
}

export const toggleApproval = (data) => {
  return {
    type: ACTION_TYPES.TOGGLE_APPROVAL,
    payload: data
  }
}

export const setApprovalLogs = (data) => {
  return {
    type: ACTION_TYPES.SET_APPROVAL_LOGS,
    payload: data
  }
}

export const setTempTaskDesc = (data) => {
  return {
    type: ACTION_TYPES.SET_TEMPORY_TASK_DESCRIPTION,
    payload: data
  }
}

/**
 * Action handler for saving nudge of a task in reducer on nudging a task from task sidebar
 */
export const setNudgeTask = (data) => {
  return {
    type: ACTION_TYPES.SET_NUDGE,
    payload: data,
  }
}

/**
 * Action handler for saving the count of incomplete subtasks of a task in reducer
 * @param {int} data 
 */

export const storeIncompleteSubtaskCount = (data) => {
  return {
    type: ACTION_TYPES.SET_INCOMPLETE_SUBTASK_COUNT,
    payload: data
  }
}

export const storeRecurrence = (data) => {
  return {
    type: ACTION_TYPES.GET_RECURRENCE,
    payload: data,
  };
};

export const storeRecurrenceDefaults = (data) => {
  return {
    type: ACTION_TYPES.GET_RECURRENCE_DEFAULTS,
    payload: data,
  };
};

export const resetRecurrence = (data) => {
  return {
    type: ACTION_TYPES.RESET_RECURRENCE,
    payload: data,
  };
};

export const setDependencyToggle = (data) => {
  return {
    type: ACTION_TYPES.SET_DEPENDENCY_TOGGLE,
    payload: data
  }
}

export const setTaskWorkflowDetails = (data) => {
  return {
    type: ACTION_TYPES.SET_TASK_WORKFLOW_DETAILS,
    payload: data
  }
}

export const setStages = (payload) => {
  return {
    type: ACTION_TYPES.SET_STAGES,
    payload: payload
  }
}

export const setFlowElements = (payload) => {
  return {
    type: ACTION_TYPES.SET_FLOW_ELEMENTS,
    payload: payload
  }
}

export const setAssignmentDefaults = (payload) => {
  return {
    type: ACTION_TYPES.SET_ASSIGNMENT_DEFAULTS,
    payload: payload
  }
}

export const setShowAttachments = (payload) => {
  return {
    type: ACTION_TYPES.SET_SHOW_ATTACHMENT,
    payload: payload
  }
}

export const setShowComments = (payload) => {
  return {
    type: ACTION_TYPES.SET_SHOW_COMMENTS,
    payload: payload
  }
}

export const setShowLinkedTasksSubTasks = (payload) => {
  return {
    type: ACTION_TYPES.SET_SHOW_LINKED_SUB_TASK,
    payload: payload
  }
}

export const setShowDescription = (payload) => {
  return {
    type: ACTION_TYPES.SET_SHOW_DESCRIPTION,
    payload: payload
  }
}

export const setShowSimplfiedDetail = (payload) => {
  return {
    type: ACTION_TYPES.SET_SHOW_SIMPLIFIED_DETAIL,
    payload: payload
  }
}

export const setScrollSection = (data) => {
  return {
    type: ACTION_TYPES.SET_SCROLL_SECTION,
    payload: data,
  }
}

export const toggleTaskOwnerShip = (data) => {
  return {
    type: ACTION_TYPES.SHOW_TASK_OWNERSHIP,
    payload: data,
  }
}

export const toggleTransferOwnerShip = (data) => {
  return {
    type: ACTION_TYPES.SHOW_TRANSFER_OWNERSHIP,
    payload: data,
  }
}

export const setCurrentView = (data) =>{
  return {
    type: ACTION_TYPES.SET_CURRENT_VIEW,
    payload: data
  }
}

export const setNewCommentCount = (data) => {
  return {
    type: ACTION_TYPES.SET_NEW_COMMENT_COUNT,
    payload: data
  }
}

export const createTaskViaSharedFlow = (data) => {
  return {
    type: ACTION_TYPES.CREATE_TASK_VIA_SHARED_FLOW,
    payload: data
  }
}

export const setSidebarLoadingState = (data) => {
  return {
    type: ACTION_TYPES.SET_SIDEBAR_LOADING_STATE,
    payload: data
  }
}

export const storeRecurrenceWorkflowAttachements = (data) => {
  return {
    type: ACTION_TYPES.SET_RECURRENCE_WORKFLOW_ATTACHEMENT,
    payload: data,
  };
};

export const storeRecurrenceflowElement = (data) => {
  return {
    type: ACTION_TYPES.SET_RECURRENCE_FLOW_ELEMENT,
    payload: data,
  };
};

export const setTaskAttachments = (payload) => {
  return {
    type: ACTION_TYPES.SET_TASK_ATTACHMENTS,
    payload: payload
  }
}

export const updateDescModeStore = (data) => {
  return {
    type: ACTION_TYPES.UPDATE_DESC_MODE,
    payload: data
  }
}

export const updateCreator = (data) => {
  return {
    type: ACTION_TYPES.UPDATE_CREATOR,
    payload: data
  }
}

export const setCloneRecurrenceEnabled = (data) => {
  return {
    type: ACTION_TYPES.CLONE_RECURRENCE_ENABLED,
    payload: data
  }
}

export const makeExistingTaskDescChanged = () => {
  return {
    type: ACTION_TYPES.EXISTING_TASK_DESC_CHANGED,
  }
}


export const taskDescLogSent = () => {
  return {
    type: ACTION_TYPES.TASK_DESC_LOG_SENT,
  }
}

export const setDescriptionPopupSetting = (payload) => {
  return {
    type: ACTION_TYPES.SET_DESCRIPTION_POPUP_SETTING,
    payload: payload
  }
}